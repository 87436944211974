import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";


const RESTAURANTS_QUERY_KEY = 'restaurants';
const RESTAURANT_QUERY_KEY = 'restaurant';

// Get Restaurants List
export function useRestaurantsList() {
    const { isLoading, data } = useQuery([RESTAURANTS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.restaurant.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { restaurantsData: data, restaurantsIsLoading: isLoading }
}

// Get Restaurant Detail
export function useRestaurantDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [RESTAURANT_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.restaurant.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { restaurantData: data, restaurantIsLoading: isLoading, isError, status }
}


// Add Restaurant Hook
export function useRestaurantAdd() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.add}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handleAddRestaurant: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([RESTAURANTS_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        addRestaurantIsLoading: mutation.isLoading,
    }
}

// update Restruant


export function useUpdateRest(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.update}/${id}`, {
		method: 'PUT',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleUpdateRest: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([RESTAURANT_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}

export function UseTagResturant(){
    const { accessToken } = getTokens();
    const mutation = useMutation(({resId , stCode}) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.tag}/${stCode}/${resId}` , {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        }
    } , true));
    return {
        handleTagResturant: async ({resId , stCode}) => {
            try {
                const res = await mutation.mutateAsync({resId , stCode})
                const data = await res.json();
                return {...data , msg:"Tagged to IRCTC Successfully"};
            } catch (error) {
                return {
                    success:false,
                    msg:"something went wrong"
                }
            }
        },
        isTagResLoading:mutation.isLoading
    }
}

export const stripRestaurantsList = (data) => {
    return {
        value: data._id,
        label: `${data.name}`
    }
}