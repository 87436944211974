import React from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddResturantForm from "../form/AddResturantForm";

import { toast } from "react-toastify";
import { useRestaurantAdd } from "../hooks/restaurants";

export default function AddResturantModal({ modalIsOpen, setModalIsOpen }) {

    const { handleAddRestaurant, addRestaurantIsLoading } = useRestaurantAdd()

    const style = {
        position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '75%',
    overflow: "scroll",
    height: "90vh",


    };

    const handleSubmit = async (values, resetForm) => {
        

     const res = await handleAddRestaurant({
            ...values,
            contact_details: {
                contact_person: values.contact_person,
                email: values.email,
                contact_number: values.contact_number,
                alt_number: values.alt_number,
            },
            bank_ac_details: {
                ifsc: values.ifsc,
                account: values.account,
                ac_holder_name: values.ac_holder_name,
            }
        }
        )
        
        if (res.success) {
            toast.success("Restaurant Added")
            console.log("added")
            resetForm({ values: '' })
            setModalIsOpen(false)
        } else {
            toast.error(res.error);
            console.log(res.error)
        }     

    }


    return (
        <>
            <Modal
                slotProps={{ backdrop: { style: { backgroundColor: "white" } } }}
                sx={style}
                open={modalIsOpen}
            >
                <div className="container-fluid modalbody">
                    <div className="d-flex justify-content-between">
                        <h5 className="m-3">Add New Resturant</h5>
                        <IconButton onClick={() => setModalIsOpen(false)}>
                            <CloseIcon />
                        </IconButton>

                    </div>
                    <div>
                        <AddResturantForm modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit}
                            isLoading={addRestaurantIsLoading}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}