import React from "react";
import { useOrdersList } from "../hooks/orders";
import FilterComponent from "../Common/FilterComponent";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link } from "react-router-dom";
import { Budget } from "../Common/CardForValue";
import { OrderStatic } from "./OrderStatic";
import { TopRes } from "./TopRes";
import { Grid } from "@mui/material";

const OrderComp = () => {
  const { ordersData, ordersIsLoading } = useOrdersList();
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
    // console.log(ordersData)

    const orderarr = ordersData || []
    const Delivered = orderarr.length>0 && orderarr.filter(function(item)
    {
         return item.status ==='ORDER_DELIVERED';
    });
    const Canceled = orderarr.length>0 && orderarr.filter(function(item)
    {
         return item.status ==='ORDER_CANCELED';
    });
  

  const [selectFilter , setSelectFilter] = React.useState('overall');

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="d-flex">
        <select className="form-control shadow-none mx-2" onChange={(event) => {setSelectFilter(event.target.value);}} aria-label="Default select example">
          <option value="overall">Overall</option>
          <option value="today">Today</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
        </select>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Order ID",
      selector: (row) =>
        (
          <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
           <Link to={`/orders/${row._id}`}>{row.orderId}</Link>
            <span>Irctc: {row.irctcOrderId}</span>
          </div>
        ),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div style={{display:'flex',flexDirection:'column',gap:'5px',padding:"2px"}}>
          <span> {row.deliveryDetails.passengerName} </span>
          <span>{row.mobile}</span>
          <span>Pnr: {row.deliveryDetails.pnr} </span>
          <span>{row.deliveryDetails.trainName}</span>
        </div>
      ),
      
    },

    {
      name: "Station Name",
      selector: (row) => <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
      <span> { row.deliveryDetails.station} </span>
      <span>{ row.deliveryDetails.stationCode }</span>
    </div>,
      sortable: true,
      minWidth: "150px",
    },

    {
      name: "Net Total",
      selector: (row) =><div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
      <span>   {row.totalAmount ? row.totalAmount.$numberDecimal : ""} Rs. </span>
      <span>
      {row.paymentType === "CASH_ON_DELIVERY" ? (
          <span className="badge badge-danger">COD</span>
        ) : (
          <span className="badge badge-success">ONLINE</span>
        )}
        </span>
    </div>,
      sortable: true,
    },
    
   ,
    {
      name: "Resturant",
      selector: (row) => <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
      <span> {  row.resturant.name} </span>
      <span>{ row.resturant.contact_details.contact_number }</span>
    </div>,
      sortable: true,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "Ordered at",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY hh:mm A"),
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "Order Status",
      selector: (row) => (
        <span className="float-right">
          {row.status === "ORDER_PLACED"  ? (
            <span className="badge badge-warning">PLACED</span>
          ) :row.status === "ODRDER_PREPARING" ? (
            <span className="badge badge-warning">PREPARING</span>
          ): row.status === "ORDER_ACCEPTED" ? (
            <span className="badge badge-primary">ACCEPTED</span>
          ) : row.status === "ORDER_READY"? (
            <span className="badge badge-success">READY</span>
          ): row.status === "ORDER_DELIVERED" ? (
            <span className="badge badge-success">DELIVERED</span>
          )
          : row.status === "ORDER_CONFIRMED" ? (
            <span className="badge badge-info">CONFIRMED</span>
          ): row.status === "ORDER_CANCELED" ? (
            <span className="badge badge-danger">CANCELED</span>
          )
          : row.status === "ORDER_CANCELLED" ? (
            <span className="badge badge-danger">CANCELLED</span>
          ): row.status === "ORDER_UNDELIVERED" ? (
            <span className="badge badge-danger">UNDELIVERED</span>
          ): row.status === "ORDER_PENDING" ? (
            <span className="badge badge-danger">UNDELIVERED</span>
          )
            :row.status === "ORDER_PARTIALLY_DELIVERED" ?  (
            <span className="badge badge-danger">PARTIALLY DELIVERED</span>
          )  :row.status === "ORDER_OUT_FOR_DELIVERY" ?  (
            <span className="badge badge-warning">OUT FOR DELIVERY</span>
          ): <span className="badge badge-warning">{row.status}</span>}
        </span>
      ),
      sortable: true,
    }
  ];

  // Filter data
  const filteredItems =
    ordersData && ordersData.length>0 && !ordersIsLoading
      ? ordersData.filter(
          (item) =>
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) !== -1
        )
      : [];

  const secondFilterItems = ordersData && ordersData.length > 0 && !ordersIsLoading ?
      filteredItems.filter((item) => {
       if(selectFilter === 'overall'){
         return item;
       }
       else if(selectFilter === "today" && new Date(item.createdAt).toDateString() === new Date().toDateString()){
         return item;
       }
       else if(selectFilter === 'month' && new Date(item.createdAt).getMonth() === new Date().getMonth())
       {
         return item;
       }else if(selectFilter === 'week' && moment(new Date()).isoWeek() === moment(item.createdAt).isoWeek())
       {
         return item;
       }
       return null;
      }) : [];
  

  return (
    <>
      <div className="card pd-20 pd-sm-40">
        <div className="d-flex flex-row-reverse justify-content-around">
          <div style={{textAlign:'right'}}>
            <Link className="btn btn-primary" to="/book-order">Add Order</Link>
          </div>
          <div>
            <h6 className="card-body-title">All Orders</h6>
            <p className="mg-b-20 mg-sm-b-30">
              Searching, ordering and paging goodness will be immediately added to
              the table, as shown in this example.
            </p>
          </div>
        </div>
        <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Total Order"} diff={12} icon={'fas fa-store'} trend="up" sx={{ height: '100%' , backgroundColor:'#cce3d5' }} value={orderarr ? orderarr.length : 0}/>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Order Delivered"} icon={'fas fa-shopping-cart'} diff={12} trend="up" sx={{ height: '100%' , backgroundColor:'#b5ebc3' }} value={Delivered.length ? Delivered.length : 0}/>
            </div>

            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Canceled Order"} icon={'fas fa-ban'} diff={12} trend="down" sx={{ height: '100%' , backgroundColor:'#f7c3c3' }} value={Canceled.length ? Canceled.length : 0}/>
            </div>


          </div>
        <div className="table-wrapper mt-3">
          {
            <DataTable
              columns={columns}
              data={secondFilterItems}
              direction="auto"
              
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              progressPending={ordersIsLoading}
              // conditionalRowStyles={conditionalRowStyles}

              subHeaderAlign="right"
              subHeaderWrap
              subHeaderComponent={subHeaderComponent}
              subHeader
            />
          }
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <OrderStatic duration={selectFilter} orderData={secondFilterItems} />
          </Grid>
          <Grid item xs={6}>
            <TopRes duration={selectFilter} orderData={secondFilterItems}/>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OrderComp;
