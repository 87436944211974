import React from 'react'
import { DataGrid, GridToolbar,gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';



const Datagrid = ({isLoading,columns,detailsRows ,}) => {
  const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));






  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  
  return (
    <div>
      <div style={{ padding: "10px 0px", width: '100%' }}>

        <StripedDataGrid disableColumnFilter
          paginationModel={paginationModel}
          pageSizeOptions={[ 10, 25,100]}
          onPaginationModelChange={setPaginationModel}
          autoHeight
          autosizing
          disableColumnSelector
          disableDensitySelector
         
           
           getRowHeight={() => 'auto'}
            sx={{
          [`& .MuiDataGrid-cell`]: {
            paddingTop: 1,
            paddingBottom: 1,
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
          },
          [`& .MuiDataGrid-columnHeader`]: {
            maxHeight: 'none !important',
            height: 'auto !important',
            whiteSpace: 'inherit !important',
            overflow: 'inherit !important',
            lineHeight: '24px !important'
          },
          [`& .MuiDataGrid-columnHeaderTitle`]: {
            whiteSpace: 'normal !important',
          },color:"black"
        }}
       
          rows={isLoading ? [] : detailsRows}
          columns={columns}
          slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress, }}
          loading={isLoading}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            },
            
          }} 
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }/>
      </div>
    </div>
  )
}

export default Datagrid
