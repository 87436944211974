import React from "react";

export default function MenuCard({ data, handleAddToCart, isLoading }) {
  return (
    <>
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
        className="menuCardBox"
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              {data.category === "veg" ? (
                <img
                  src={"/assets/img/veg.png"}
                  alt="Delicious Authentic Cuisine at Yatri Restro - Best Train Restaurant in India."
                  width={"15px"}
                  height={"15px"}
                />
              ) : (
                <img
                  src={"/assets/img/nonVeg.png"}
                  alt="Delicious Authentic Cuisine at Yatri Restro - Best Train Restaurant in India."
                  width={"15px"}
                  height={"15px"}
                />
              )}
            </div>
            <div>{data.itemName}</div>
          </div>

          <div className="fs-14 text-muted">{data.description} </div>
          <div className="fs-14">
            {data.category === "veg" ? (
              <span class="badge badge-success">Veg</span>
            ) : (
              <span class="badge badge-danger">Non-Veg</span>
            )}
          </div>
          <div className="fw-500">
            {/* <del>₹{data.cost_price.$numberDecimal}</del> */}
            <span className="text-danger pl-1">
              ₹{data.basePrice ? data.basePrice.$numberDecimal : ""}
            </span>
          </div>
        </div>

        <div className="qty-td addGQty col-3 text-right pl-0">
          {!isLoading && (
            <button
              type="button"
              className="btn btn-danger py-0 px-4"
              onClick={() => handleAddToCart(data)}
            >
              ADD
            </button>
          )}
        </div>
      </div>
    </>
  );
}
