import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'

import { Box, FormControl, InputLabel, MenuItem, OutlinedInput } from '@mui/material'

import * as Yup from 'yup'
import { useUpdateRest } from '../hooks/restaurants'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Newselect from '../Common/Newselect'
import TextField from '../Common/MuiTextField'

const AddDiscount = ({setDiscountModal}) => {
   
        const { id } = useParams()
        const { handleUpdateRest } = useUpdateRest(id)
        const handleSubmit = async (values) => {
            console.log(values)
            const res = await handleUpdateRest(values)
    
            if (res.success) {
                toast.success("Discount Added")
                setDiscountModal(false)
                console.log("updated")
            } else {
                toast.error(res.error)
                console.log(res.error)
            }
        }
    
    const validate=Yup.object({
        discountType:Yup.string().required("Choose discount type"),
        discount:Yup.string().required("Enter Discount Amount"),
        maxDiscount:Yup.number().required("Enter max discount"),
        minOrderAmount:Yup.number().when('discountType', {
            is:  (value) => value === 'PERCENTAGE',
            then: (schema) => schema.required('Enter minimum order amount'),
            otherwise:  (schema) => schema,
          })
    })
    return (
        <div>
            <Formik
                initialValues={{
                    discountType: "",
                    discount: "",
                    maxDiscount: "",
                    
                }}
                validationSchema={validate}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form>
                        <div className='row pt-2'>
                            <div className='col-md-6 mt-3 mt-md-2 '>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Discount Type</InputLabel>
                                        <Newselect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="discountType"
                                            value={formik.values.discountType}
                                            onChange={formik.handleChange}
                                            size="small"
                                            input={<OutlinedInput label="Discount Type" />}
                                        >
                                            <MenuItem value="PERCENTAGE">Percentage </MenuItem>
                                            <MenuItem value="FLAT">Flat</MenuItem>
                                        </Newselect>
                                      
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='col-md-6 mt-3 mt-md-0'>
                                <TextField
                                    name="discount"
                                    label="Discount" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mt-3'>
                                <TextField
                                    type="number"
                                    name="maxDiscount"
                                    label="Maximum Discount" />
                            </div>
                            <div className='col-md-6 mt-3'>
                                {formik.values.discountType == "PERCENTAGE" ? (<TextField
                                    type="number"
                                    name="minOrderAmount"
                                    label="Minimum Order Amount" />) : ("")}

                            </div>
                        </div>
                        <div className='float-end'>
                            <button className="btn btn-success className='col-md-6 mt-3 '" type="submit">
                                Add Discount
                            </button>
                        </div>


                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default AddDiscount
