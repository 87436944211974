import { Container, Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { useOrderDetail } from '../hooks/orders';
import OrderInformation from './OrderInformation';
import { useUser } from '../hooks/user';
import Header from '../Header/Header';
import Sidenav from '../Sidenav/Sidenav';

export default function OrderDetailPage() {
    const { id } = useParams();
    const { orderData, orderIsLoading } = useOrderDetail(id);
    const { user, isLoading } = useUser();

    return <>
        <Header user={user}/>
        <Sidenav/>
        <Container maxWidth="xxl">
            <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                    {orderIsLoading ? <Loader /> : <OrderInformation data={orderData?.data} />}
                </Grid>
            </Grid>
        </Container>   
    </>
}
