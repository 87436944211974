import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'

import { toast } from 'react-toastify'
import Newselect from '../Common/Newselect'
import SubmitButton from '../Common/Button'
import TextField from '../Common/MuiTextField'

const NewOrderStatusForm = ({ data, }) => {

    const ROLES_OPTIONS = [

        { 'label': 'ORDER DELIVERED', value: 'ORDER_DELIVERED' },
        { 'label': 'ORDER CANCELLED', value: 'ORDER_CANCELLED' },

    ]

    const ORDER_CANCELED_REASON = [
        { 'label': 'BEYOND SERVICE HOUR', value: 'BEYOND_SERVICE_HOUR' },
        { 'label': 'TRAIN DELAYED', value: 'TRAIN_DELAYED' },
        { 'label': 'LAW N ORDER', value: 'LAW_N_ORDER' },
        { 'label': 'NATURAL CALAMITY', value: 'NATURAL_CALAMITY' },
        { 'label': 'PASSENGER JOURNEY CANCELLED', value: 'PASSENGER_JOURNEY_CANCELLED' },
    ]

 

    const updateStatus = async (values) => {
        console.log(values)

    }

    return (
        <div>
            <Formik
                initialValues={{
                    status: data ? data.status : "",
                    remarks: data && data.remarks ? data.remarks : "",
                    additionalRemarks: "Ok",
                    otp: "",

                }}
                onSubmit={updateStatus}
                enableReinitialize
            >
                {(formik) => (
                    <Form>
                        <div className='row'>
                            <div className='col-md-12  '>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Order Status</InputLabel>
                                        <Newselect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                            size="small"
                                            input={<OutlinedInput label="Order Status" />}
                                        >
                                            {ROLES_OPTIONS.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='col-12'>

                                {
                                    formik.values.status == 'ORDER_DELIVERED' && (<>
                                      <div className='mt-3'>
                                            <TextField name="otp" label="Enter OTP" />
                                            </div>
                                    </>)
                                }

                                {
                                    formik.values.status == 'ORDER_CANCELLED' && (<>
                                        <div className='mt-3'>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Reason</InputLabel>
                                                    <Newselect
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="remarks"
                                                        value={formik.values.remarks}
                                                        onChange={formik.handleChange}
                                                        size="small"
                                                        input={<OutlinedInput label="Reason" />}
                                                    >
                                                        {ORDER_CANCELED_REASON.map((element, id) => {
                                                            return (
                                                                <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Newselect>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </>)
                                }
                               
                            </div>
                            <div className="col-md-12 mt-3">
                                    <TextField name="additionalRemarks" label="Additional Remarks" />
                                </div>

                                <div className=' col-12 mt-3 '>
                                    <SubmitButton className="float-right " label="Update" />
                                </div>
                                <div>

                                    <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.remarks && formik.touched.remarks ? formik.errors.remarks : null}</span>
                                </div>
                        </div>


                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default NewOrderStatusForm
