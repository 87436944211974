import React from 'react'
import { toast } from 'react-toastify'
// import PageBreadcrumbs from '../../components/Common/Breadcrumbs'
import { useRestaurantAdd } from '../hooks/restaurants'
// import headerContext from '../../context/header/headerContext'
import AddRestaurantForm from './AddRestaurantForm';
import Sidenav from '../Sidenav/Sidenav';
import Header from '../Header/Header';
import { useUser } from '../hooks/user';
import { useNavigate } from 'react-router-dom';

export default function AddRestaurantPage() {
   const navigate =useNavigate()
    const { user} = useUser();
    const { handleAddRestaurant, addRestaurantIsLoading } = useRestaurantAdd();
    const handleSubmit = async (values, resetForm) => {
        const res = await handleAddRestaurant({
            ...values,
            contact_details: {
                contact_person: values.contact_person,
                email: values.email,
                contact_number: values.contact_number,
                alt_number: values.alt_number,
            },
            bank_ac_details: {
                ifsc: values.ifsc,
                account: values.account,
                ac_holder_name: values.ac_holder_name,
            }
        });
        if (res.success) {
            toast.success('Restaurant added successfully.');
            resetForm({ values: '' });
            navigate('/restuarants')
        } else {
            toast.error(res.error);
        }
    }
    return (
        <>
           <Header user={user}/>

<Sidenav/>
        <div className="content-wrapper" style={{padding:"20px"}}>  
            <h6 className="text-center">Add New Restaurant</h6>
            <p className="text-center">Please provide the required details below to register your application with us.</p>

            <div className="form-layout">
                <div className='container' >
                    <div className='col-12'>
                        <AddRestaurantForm
                            isLoading={addRestaurantIsLoading}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
