import React from 'react'
import { useUser } from '../../components/hooks/user';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import CancelContent from '../../components/CancelComponent/CancelContent';

const CancelReq = () => {
    const { user } = useUser();
    return (
      <div>
        <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">
<CancelContent/>
</div>
      </div>
    )
  }


export default CancelReq;