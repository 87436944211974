import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TagRestaurantIrctc from "../ResturantContent/TagRestaurantIrctc";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width:"70%"
};


export function TagToIrctcModal({ tagModal, setTagModal, data, resId }) {

    return (
        <div>
            <Modal
                open={tagModal}
                onClose={() => setTagModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Tag To IRCTC</h5>
                            <IconButton onClick={() => setTagModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                            <TagRestaurantIrctc setTagModal={setTagModal} data={data} resId={resId} />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}

