import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import EnquiryComp from '../../components/Enquiry/EnquiryComp';

const EnquiryPage = () => {
    const { user } = useUser();
    return (
      <div>
        <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">

<EnquiryComp/>

</div>
      </div>
    )
  }

export default EnquiryPage