
import React from "react";
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ItemDetails from "../Item/ItemDetail";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '60%',

};


export function ViewItemModal({ itemModal, setItemModal, id }) {

  return (
    <div>
      <Modal
        open={itemModal}
        onClose={() => setItemModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
     
      >
        <Box sx={style}>
        <div className="container-fluid modalbody">
          <div className="d-flex justify-content-between">
            <h5 className="">Item Details</h5>
            <IconButton onClick={() => setItemModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <ItemDetails id={id} />
          </div>
        </div>
        </Box>
      </Modal>
    </div>
  )

}

