import React from 'react'
import AddFoodForm from '../form/AddFoodForm'

const FoodAddModal = () => {
  return   <div className="modal fade" id="exampleModalFood" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog" role="document">
 <AddFoodForm className="modal-content" />
</div>
</div>
  
}

export default FoodAddModal