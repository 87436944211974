
import React from "react";
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';

import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useUpdateItem, useViewitem } from "../hooks/item";
import UpdateItemForm from "../form/UpdateItemForm";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '75%',
    overflow: "scroll",
    height: "82vh",
};


export function UpdateItemModal({ updateModal, setUpdateModal, id }) {

    return (
        <div>
            <Modal
                open={updateModal}
                onClose={() => setUpdateModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
              
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Update Items</h5>
                            <IconButton onClick={()=>setUpdateModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                            <UpdateItemForm id={id} updateModal={updateModal} setUpdateModal={setUpdateModal}/>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}


