import React from 'react'
import FilterComponent from '../Common/FilterComponent';
import Loader from '../Loader';
import DataTableBase from '../Common/DataTableBase';
import { useCancelList } from '../hooks/cancelreq';
import UpdateCancelreqModel from '../model/UpdateCancelreqModel';
import ViewCancelReqModel from '../model/ViewCancelreqModel';

const CancelContent = () => {
    const {cancelreqdata,cancelIsLoading}  = useCancelList()

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [state,setState] = React.useState({})
    const [flag, setFlag] = React.useState(false);

    const changeState = ()=>{
     setFlag(false)
     setState({})
   }

   const arr = cancelreqdata || []
   const active = arr.filter(function(item)
   {
        return item.status ==="active";
   });

   const inProcess = arr.filter(function(item)
   {
        return item.status ==="approved";
   });
   const closed = arr.filter(function(item)
   {
        return item.status ==="reject";
   });
   const created = arr.filter(function(item)
   {
        return item.status ==="new";
   });

    const subHeaderComponent = React.useMemo(() => {
     const handleClear = () => {
         if (filterText) {
             setResetPaginationToggle(!resetPaginationToggle);
             setFilterText("");
         }
     };

     return (
         <FilterComponent
             onFilter={e => setFilterText(e.target.value)}
             onClear={handleClear}
             filterText={filterText}
         />
     );
 }, [filterText, resetPaginationToggle]);


 const columns = [
     {
         name: 'Name',
         selector: row => row.name,
         cell: row => row.name,
         sortable: true,
     },
     {
         name: 'Mobile',
         selector: row => row.mobile
     },
     {
         name: 'PNR',
         selector: row => row.pnr,
         cell: row=> row.pnr,
         sortable: true,
     },
     {
         name: 'status',
         selector: row => {
             if(row.status === "new"){
                return <span className="badge badge-primary">{row.status}</span>
             }else if(row.status === "reject"){
                return <span className="badge badge-danger">{row.status}</span>
             }
             else if(row.status === "active"){
                return <span className="badge badge-success">{row.status}</span>
             }
             else if(row.status === "approved"){
                return <span className="badge badge-warning">{row.status}</span>
             }
         }
     },
    
     {
         name: 'Update',
         selector: row => <button className="btn btn-primary" 
         data-toggle="modal"
         data-target="#exampleModalcancelupdate"
         data-whatever="@getbootstrap"
         onClick={() =>{
          setState(row)
         } }>Update</button>
     },
     {
         name: 'View',
         selector: row => <button className="btn btn-primary"
         data-toggle="modal"
         data-target="#exampleModalupdatethree"
         data-whatever="@getbootstrap"
         onClick={() =>{
          setState(row)
         } }>View</button>
     },
 ];

 if (cancelIsLoading) {
     return <Loader />
 }



    const filteredItems =cancelreqdata? cancelreqdata.filter(
     item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
 ) : [];

 return (
     <>
    
     <div className="card pd-20 pd-sm-40">
     <h6 className="card-body-title">All Enquiry</h6>
     <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>
     <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Enquiry</span>
                  <span className="info-box-number">{arr.length}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-shopping-cart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Active Enquiry</span>
                  <span className="info-box-number">{active.length}</span>
                </div>
              </div>
            </div>




            <div className="clearfix hidden-md-up" />
          
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Approved Enquiry</span>
                  <span className="info-box-number">
                    {inProcess.length}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Closed Enquiry</span>
                  <span className="info-box-number">
                    {closed.length}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-gradient-warning elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Created Enquiry</span>
                  <span className="info-box-number">
                    {created.length}
                  </span>
                </div>
              </div>
            </div>

      
          </div>

     {/* <OutlinedButton className='col-2' label='Add new restaurant' onClick={() => navigate('/restaurants/add')}/> */}
     <div className="table-wrapper">
         {
             <DataTableBase
             columns={columns}
                 data={filteredItems}
                 direction="auto"
                 fixedHeaderScrollHeight="300px"
                 pagination
                 responsive
                 progressPending={cancelIsLoading}
                 // conditionalRowStyles={conditionalRowStyles}
                 
                 subHeaderAlign="right"
                 subHeaderWrap
                 subHeaderComponent={subHeaderComponent}
                 subHeader
                 />
             }
     </div>
     
     <UpdateCancelreqModel id="exampleModalcancelupdate"
      item = {state}
      flag={flag}
      changeState={changeState}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
 />


<ViewCancelReqModel
         id="exampleModalupdatethree"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true"
         item={state}
       />
 </div>

 
         </>
     )
}
export default CancelContent