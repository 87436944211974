import { Form, Formik } from 'formik'
import React from 'react'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from 'react-router-dom'
import { useUpdateRest } from '../hooks/restaurants'
import { toast } from 'react-toastify'

const ScheduleClosedform = ({setScheduleModal} ) => {
    const { id } = useParams()
    const { handleUpdateRest } = useUpdateRest(id)

    const handleSubmit = async(values) => {
      
        const res = await handleUpdateRest(values)

        if (res.success) {
            toast.success("Restaurant Updated")
            setScheduleModal(false)
            console.log("updated")
        } else {
            toast.error(res.error)
        }
    }
    return (

        <div>
            <Formik
                initialValues={{
                    closedFrom: "",
                    closedTo: "",
                }}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form>
                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={moment(formik.values.closedFrom,'YYYY-MM-DD HH:mm:ss')}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("closedFrom", moment.utc( value).startOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                                            name="closedFrom" label="Closed From" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={moment(formik.values.closedTo,'YYYY-MM-DD HH:mm:ss')}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("closedTo",moment.utc( value).endOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                                            name="closedTo" label="Closed To" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='float-end'>
                            <button className="btn btn-success className='col-md-6 mt-3 '" type="submit">
                                Update
                            </button>
                        </div>


                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ScheduleClosedform
