import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

import { Chart as chartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

chartJS.register(
  ArcElement, Tooltip, Legend
)


export function OrderStatic({ duration, orderData }) {
  let pendingCount = 0;
  let DeliveredCount = 0;
  let CancelCount = 0;
  for(let i=0; i<orderData.length; i++){
    if(orderData[i].status === "ORDER_DELIVERED"){
      DeliveredCount++;
    }else if(orderData[i].status === "ORDER_CANCELLED" || orderData[i].status === "ORDER_CANCELED"){
      CancelCount++;
    }else{
      pendingCount++;
    }
  }

  const data = {
    labels: [
      'Pending',
      'Delivered',
      'Cancelled'
    ],
    datasets: [{
      label: 'Orders',
      data: [pendingCount, DeliveredCount, CancelCount],
      backgroundColor: [
        'rgb(54, 162, 235)',
        'rgb(5, 200, 46)',
        'rgb(255, 99, 132)',
      ],
      hoverOffset: 4
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        color: 'white',
        formatter: function (value, context) {
          return context.chart.data.labels[
            context.dataIndex
          ];
        },
      },
      title: {
        display: true,
        text: "Reported Fault Allocation",
        color: "#D6001C",
        font: {
          family: "AvenirNextLTW01-Regular",
          size: 16,
          style: 'normal'
        }
      },
    },
    elements: {
      point: {
        radius: 0
      }
    },
  }

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx , data } = chart;
      let total = 0;
      for (let i=0; i<data.datasets[0].data.length; i++){
        total += data.datasets[0].data[i];
      }
      ctx.save();
      ctx.font = 'bolder 20px sans-serif';
      ctx.fillStyle = 'gray'
      ctx.textAlign = 'center';
      ctx.textBaseLine = 'middle';
      ctx.fillText(`Total : ${total}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  return (
    <Card>
      <CardHeader title={`Order statistics ${duration}`} />
      <Divider />
      <List>
        <Doughnut data={data} options={options} plugins={[textCenter]}
          style={{
            height: "400px",
            width: "400px",
            minHeight: "400px",
            minWidth: "300px",
            position: 'relative',
          }}></Doughnut>
      </List>
      <Divider />
    </Card>
  );
}