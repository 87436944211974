import React from 'react'
import Sidenav from '../components/Sidenav/Sidenav'
import Header from '../components/Header/Header'
import Dashboard from '../components/Dashboard/Dashboard'
import { useUser } from '../components/hooks/user'

const MainPage = () => {
  const { user } = useUser();
  return (<>
  
     <Header user={user}/>

    <Sidenav/>
    <Dashboard user = {user}/> 
    
  </>
  )
}

export default MainPage;