import { Form, Formik } from 'formik'
import React from 'react'
import { useRestaurantsList,stripRestaurantsList} from '../hooks/restaurants'
import { Autocomplete, LinearProgress, TextField } from '@mui/material'
import {useChangeRes} from "../hooks/orders"
import { toast } from 'react-toastify'
import SubmitButton from '../Common/Button'


const ChangeResForm = ({data,setOpenModal}) => {
    const{restaurantsData,restaurantsIsLoading,}=useRestaurantsList()
   
    const {handleChangeRes,isChangeLoading}=useChangeRes(data._id)
    const handleSubmit = async (values) => {
        const res = await handleChangeRes(values)
        
        if (res.success) {
            setOpenModal(false)
            toast.success("Order Status Updated Successfully");
        } else {
            toast.error(res.error);
        }

    }
  return (
    <div>
    <Formik
    initialValues={{
       restaurantId:""
    }}
    onSubmit={handleSubmit}
  >
        {(formik)=>(
            <Form>
                
                    <div className='col-md-12 mt-2'>
                              <Autocomplete
                                    disablePortal
                                    size='small'
                                    options={restaurantsData?.map(stripRestaurantsList)}
                                    value={formik.values.restaurantId?.label}
                                    slots={{ loadingOverlay: LinearProgress, }}
                                    loading={restaurantsIsLoading}
                                    onChange={(e, value) =>value==null?value="": formik.setFieldValue("restaurantId", value.value)}
                                    slotProps={{ textField: { size: 'small' } }}
                                    renderInput={(params) => <TextField name=" restaurantId"      {...params} label="Select Restaurant" />}
                                    isOptionEqualToValue={(options, value) => options.value === value.value}
                                />
                            </div>
                            <div className='float-end'>
                           
                        <div className=' col-12 mt-3 '>
                            <SubmitButton className="float-right" isLoading={isChangeLoading} label="Update" />
                        </div>
                        </div>
               
            </Form>
        )}
        
    </Formik>
  
</div>
  )
}

export default ChangeResForm
