import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import CusineCont from '../../components/CusineComp/CusineCont';

const Cusine = () => {
    const { user } = useUser();

    return (
      <div>
        <Header user={user} />
        <Sidenav />
        <div className="content-wrapper">
<CusineCont/>

        </div>
      </div>
    );
  };

export default Cusine