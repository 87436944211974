import React, { useState } from 'react'
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import { Switch, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import UploadMenuModel from '../model/UploadMenuModel';
import UpdateRestaurantModalModal from '../model/UpdateRestaurant';
import { useParams } from 'react-router-dom';
import { useUpdateRest } from '../hooks/restaurants';
import { AddItemModal } from '../model/AddItemModel';
import ViewItem from '../Item/Viewitems';
import { TagToIrctcModal } from '../model/TagToIrctcModal';
import { ScheduleClosed } from '../model/ScheduleClosed';
import { useUser } from '../hooks/user';
import { AddDiscountModal } from '../model/AddDiscountModel';

const RestaurantInformation = ({ data }) => {
    const {user}=useUser()
    
    const [menuModal, setMenuModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [additemModal, setAddItemModal] = useState(false)
    const [tagModal, setTagModal] = useState(false)
    const [scheduleModal, setScheduleModal] = useState(false)
    const [discountModal, setDiscountModal] = useState(false)

    const [flag, setFlag] = useState(false)
    const { id } = useParams();
    const { handleUpdateRest } = useUpdateRest(id);


    const updateStatus = async (val) => {
        const res = await handleUpdateRest({ status: val });
        if (res.success) {
            toast.success("Status Updated");
        } else {
            toast.error(res.error);
        }
    };

    return (
        <section>
            <div className='container-fluid'>
                <Card >
                    <CardContent>
                        <div className='row'>
                            <div className="col-lg-4">
                            <div
                            style={{
                                cursor: "pointer",
                            }}
                        >
                          {data.status=="Active"?<span className='badge badge-success'>{data.status}</span>:<span className='badge badge-danger'>{data.status}</span>}
                            <Switch
                                defaultChecked={
                                    data.status === "Active" ? true : false
                                }
                                color="secondary"
                                onChange={(e) => updateStatus(data.status == "Active" ? "Closed" : "Active")}
                            />
                        </div>

                            <div>
                            <div className='text-center'><img src="../../dist/img/resturant.jpg" width="100" height="100" alt="img" /></div>
                            <p className='fw-bolder text-center mt-3'>{data.name}</p>
                        </div>
                                <Card className='p-3 card_retsro'>
                                        <h5 className=" text-center bg-dark text-light p-2">Restaurant Details</h5>
                                    <ul className='list-unstyled'>
                                        <li className='mt-3'>
                                            <strong>ID :</strong> <span className='float-right'>{data.restoId}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Firm Name:</strong> <span className='float-right'>{data.firm_name}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Firm Type:</strong> <span className='float-right'>{data.firm_type}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>FSSAI Status:</strong> <span className='float-right'>{data.fssai_status === true ? "Yes" : "No"}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>FSSAI License No.:</strong> <span className='float-right'>{data.fssai_lic_num}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>GSTIN:</strong> <span className='float-right'>{data.gstin}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <b>Restaurant Type :</b>
                                            <span
                                                className="float-right"
                                                style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {data.restaurant_type.map((e) => {
                                                    return (
                                                      <>
                                                      {e=="Non-Veg"?<span className='badge badge-danger'>Non-Veg</span>:<span className='badge badge-success'>Veg</span>}
                                                      </>
                                                    );
                                                })}
                                            </span>

                                        </li>

                                        <li className='mt-3'>
                                            <strong>Address:</strong> <span className='float-none'>{data.address}</span>
                                        </li>
                                    </ul>
                                </Card>
                            </div>
                            <div className="col-lg-4">
                                <Card className='p-3 pb-0 card_retsro'>
                                    
                                        <h5 className=" text-center bg-dark text-light p-2">More Details</h5>
                                    
                                    <ul className='list-unstyled'>
                                        <li className='mt-3'>
                                            <strong>Opening Time:</strong> <span className='float-right'>{data.opening_time}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Closing Time:</strong> <span className='float-right'>{data.closing_time}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Minimum Order Time:</strong> <span className='float-right'>{data.min_order_time}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Minimum Order Amount:</strong> <span className='float-right'>{data.min_order_amount
                                                ? `Rs.${data.min_order_amount.$numberDecimal}`
                                                : ""}
                                            </span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Delivery Type:</strong> <span className='float-right'>{data.delivery_type}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Rating:</strong> <span className='float-right'>{data.rating}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Station Distance:</strong> <span className='float-right'>{data.station_distance}</span>
                                        </li>
                                        <li className='mt-1'>
                                            <strong>Station :</strong>

                                            <table class="table table-sm ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Station name</th>
                                                        <th scope="col">Station Code</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.station.map((e, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{e.city}</td>
                                                                <td>{e.stationName}</td>
                                                                <td>{e.stationCode}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </li>
                                    </ul>
                                    
                                    <ul className='list-unstyled'>
                                      
                                            <h6 className="text-center bg-dark text-light p-2">Bank Details</h6>
                                        
                                        <li className='mt-4'>
                                            <strong>Account Holder Name :</strong> <span className='float-right'>{data.bank_ac_details.ac_holder_name}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Account Number :</strong> <span className='float-right'>{data.bank_ac_details.account}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>IFSC Code :</strong> <span className='float-right'>{data.bank_ac_details.ifsc}</span>
                                        </li>
                                    </ul>
                                  
                                </Card>
                            </div>
                            <div className="col-lg-4">
                            <Card className='mt-2'>
                                    <div className="text-center ">
                                        <h6 className=" text-center bg-dark text-light p-2">Balance</h6>
                                        <h2 style={{ color: "gold", fontSize: "40px", padding: "20px" }}>
                                            Rs. {data.wallet.$numberDecimal}
                                        </h2>

                                    </div>
                                </Card>
                                <Card className='mt-5'>
                                        <h6 className=" text-center bg-dark text-light p-2">Actions</h6>    
                                    <div className='d-flex p-3 justify-content-center flex-column ' style={{gap:"15px"}}>
                                        <button  type="button" className="btn btn-primary pb-2" onClick={() => setDiscountModal(true)}>Discount <i class="fas fa-tag"></i></button>
                                        {user?.role === "admin" || user?.role === "superadmin" ? (<button type="button" class="btn btn-primary" onClick={() => setMenuModal(true)}>Upload Menu  <i class="fa fa-bars"></i></button>) : ("")
                                        }
                                        <button type="button" class="btn btn-primary  pb-2" onClick={() => setUpdateModal(true)}>Update  <i class="far fa-edit"></i></button>
                                        {flag == false ? <button type="button" class="btn btn-primary " onClick={() => setFlag(true)}>View item <i className="fa fa-eye"></i></button>
                                            : <button type="button" class="btn btn-primary " onClick={() => setFlag(false)}>Hide item <i className="fa fa-eye"></i></button>}
                                        <button type="button" class="btn btn-success" onClick={() => setAddItemModal(true)}>Add Item <i className="fa fa-plus"></i></button>
                                        {user?.role === 'admin' || user?.role === 'superadmin' || user?.role === 'manager' ? (<button type="button" class="btn btn-success" onClick={() => setTagModal(true)}> Tag to IRCTC <i className="fa fa-tag"></i></button>) : ("")}
                                        {user?.role === 'admin' || user?.role === 'superadmin' || user?.role === 'manager' ? (<button type="button" class="btn btn-success" onClick={() => setScheduleModal(true)}> Schedule Closed <i className="fa fa-lock"></i></button>) : ("")}
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className='row py-3'>
                            <div className='col-lg-4 '>
                                <Card className='p-3'>
                                <ul className='list-unstyled'>
                                       
                                            <h6 className="text-center bg-dark text-light p-2">Contact Details</h6>
                                        
                                        <li className='mt-2'>
                                            <strong>Contact Name :</strong> <span className='float-right'>{data.contact_details.contact_person}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Contact Number :</strong> <span className='float-right'>{data.contact_details.contact_number}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Contact Email :</strong> <span className='float-right'>{data.contact_details.email}</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Alternate Number:</strong> <span className='float-right'>{data.contact_details.alt_number}
                                            </span>
                                        </li>
                                    </ul>
                                </Card>
                              </div>
                            <div className='col-lg-4 '>
                            <Card className='p-3'>
                                <ul className='list-unstyled'>
                                        
                                            <h6 className=" text-center bg-dark text-light p-2">Delivery Boy Details</h6>
                                        
                                        <li className='mt-2'>
                                            <strong>Name :</strong> <span className='float-right'>John Doe</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Number :</strong> <span className='float-right'>1234567890</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Email :</strong> <span className='float-right'>jd@gmail.com</span>
                                        </li>
                                        <li className='mt-3'>
                                            <strong>Alternate Number:</strong> <span className='float-right'>1234567890
                                            </span>
                                        </li>
                                    </ul>
                                </Card>
                            </div>
                            <div style={{width:"100%",padding:"10px"}}>
                            {flag === true ? <ViewItem id={data._id} /> : ""}
                            </div>
                        </div>
                        <UploadMenuModel menuModal={menuModal} setMenuModal={setMenuModal} />
                        <UpdateRestaurantModalModal updateModal={updateModal} setUpdateModal={setUpdateModal} data={data} />
                        <AddItemModal additemModal={additemModal} setAddItemModal={setAddItemModal} />
                        <TagToIrctcModal tagModal={tagModal} setTagModal={setTagModal} data={data.station} resId={data._id} />
                        <ScheduleClosed scheduleModal={scheduleModal} setScheduleModal={setScheduleModal} />
                        <AddDiscountModal discountModal={discountModal} setDiscountModal={setDiscountModal}/>
                    </CardContent>
                </Card>
            </div>

        </section>
    )
}

export default RestaurantInformation

