import { Link } from "react-router-dom";
import { useUser } from "../hooks/user";

// import "../../../public/img/favicon.png"
export default function Sidenav() {
  const { user } = useUser();


  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/" className="brand-link">
          <img
            src="/img/favicon.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">Yatri Restro</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>Dashboard</p>
                  </Link>
                </li>

                {user.role === "admin" ||
                user.role === "superadmin" ||
                user.role === "staff" ||
                user.role === "manager" ||
                user.role === "accountant" ||
                user.role === "supportExecutive" ||
                user.role === "floorManager" ? (
                  user.role === "supportExecutive" ||
                  user.role === "floorManager" ||
                  user.role === "accountant" ? (
                    <li className="nav-item">
                      <Link to="/restuarants" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>My Restuarants</p>
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li className="nav-item">
                        <Link to="/restuarants" className="nav-link">
                          <i className="nav-icon fas fa-th" />
                          <p>My Restuarants</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/vendor" className="nav-link">
                          <i className="nav-icon fas fa-address-card" />
                          <p>Vendor Register</p>
                        </Link>
                      </li>
                    </>
                  )
                ) : (
                  ""
                )}

                {user.role === "admin" ||
                user.role === "superadmin" ||
                user.role === "supportExecutive" ||
                user.role === "floorManager" ||
                user.role === "manager" ||
                user.role === "accountant" ? (
                  <li className="nav-item">
                    <Link to="/order" className="nav-link">
                      <i className="nav-icon fas fa-shopping-cart" />
                      <p>Order</p>
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {user.role === "admin" ||
                user.role === "superadmin" ||
                user.role === "manager" ? (
                  <>
                    <li className="nav-item">
                      <Link to="/stations" className="nav-link">
                        <i className="nav-icon fas fa-train" />
                        <p>Stations</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/enquiry" className="nav-link">
                        <i className="nav-icon fas fa-question-circle" />
                        <p>Enquiry</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/featureitem" className="nav-link">
                        <i className="nav-icon fas  fa-list-alt" />
                        <p>Feature Item</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/customers" className="nav-link">
                        <i className="nav-icon fas fa-user-friends" />
                        <p>Customer</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/users" className="nav-link">
                        <i className="nav-icon fas fa-user" />
                        <p>Users</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/cusine" className="nav-link">
                        <i className="nav-icon fas fa-bread-slice" />
                        <p>Cusine</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/foodtype" className="nav-link">
                        <i className="nav-icon fas fa-cheese" />
                        <p>Food Type</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/cancel-req" className="nav-link">
                        <i className="nav-icon fas fa-times" />
                        <p>Cancel Req</p>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </>

              {user.role === "admin" ||
              user.role === "superadmin" ||
              user.role === "editor" ? (
                <>
                  <li className="nav-item">
                    <Link to="/blog" className="nav-link">
                      <i className="nav-icon fas fa-blog" />
                      <p>Blog</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/location" className="nav-link">
                      <i className="nav-icon fas fa-map-marker" />
                      <p>Location</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/pages" className="nav-link">
                      <i className="nav-icon fas fa-clone" />
                      <p>Page</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
