import React from 'react'
import { UseTagResturant } from '../hooks/restaurants'
import { toast } from 'react-toastify'

const TagRestaurantIrctc = ({ setTagModal, data, resId }) => {
    console.log(data)
    const {handleTagResturant,isTagResLoading}=UseTagResturant()

    const tagfunction = async (stCode) => {
        const data = await handleTagResturant({resId , stCode});
        if(data.success){
            toast.success(data.msg);
        }else{
            toast.error(data.msg)
        }
    }

    return (
        <div>
            
            <table class="table table-sm  " >
                <thead>
                    <tr>
                        <th scope="col">City</th>
                        <th scope="col">Station name</th>
                        <th scope="col">Station Code</th>
                    </tr>
                </thead>
                <tbody >
                    {data?.map((e, i) => {
                        return (
                            <>
                            <tr >
                                <td>{e.city}</td>
                                <td>{e.stationName}</td>
                                <td>{e.stationCode}</td>
                                <td> <button className='btn btn-success float-end'onClick={() => tagfunction(e.stationCode)}>{isTagResLoading  ? "Loading" : "Tag"}</button></td>
                            </tr>
                           
                            </>
                        );
                    })}
                </tbody>
            </table>
         
        </div>
    )
}

export default TagRestaurantIrctc
