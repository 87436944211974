import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useResetPassword } from '../hooks/user'
import { toast } from 'react-toastify'
import SubmitButton from '../Common/Button'
import TextField from '../Common/MuiTextField'

const ResetPasswordForm = ({setOpenModal,user}) => {
    const validate=Yup.object({
        currentPassword:Yup.string().required("Please enter your current password"),
        newPassword:Yup.string().min(6).required("Please enter your new password"),
        confirmPassword:Yup.string().min(6).required("Please enter your new password again")
    })

const {handleResetPassword,handleResetLoading}=useResetPassword(user._id)

    const handleSubmit=async(values)=>{
      
        if(values.newPassword===values.confirmPassword){
          
            const res=await handleResetPassword(values)
            if(res.success){
                setOpenModal(false)
                toast.success("Password Changed")    
            }else{
                toast.error(res.error)
            }
        }else{
            toast.error("Passwords not matching")
        }

    }
  return (
    <div>
        <Formik
        initialValues={{
            currentPassword:"",
            newPassword:"",
            confirmPassword:""
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}>
            {(formik)=>(
                <Form>
                    <div className="row ">
                        <div className="col-12 mt-3">
                            <TextField type="password" name="currentPassword" label="Current Password"/>
                        </div>
                        <div className="col-12 mt-3">
                            <TextField  type="password" name="newPassword" label="New Password"/>
                        </div>
                        <div className="col-12 mt-3">
                            <TextField  type="password" name="confirmPassword" label="Confirm Password"/>
                        </div>
                        
                        <div className=' col-12 mt-3 '>
                            <SubmitButton className='float-end' label="Reset" />
                        </div>
                      
                      
                    </div>
                </Form>
            )}
            
        </Formik>
      
    </div>
  )
}

export default ResetPasswordForm
