import { Box, Card, CardContent, Typography } from "@mui/material";

export default function CancelReqDetail({item}){
    
 return <>
<Card variant="outlined"  sx={{ minWidth: 700 }}>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3 }} >
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Name
          </Typography>
          <Typography component="div" variant="p">
            {item.name}
          </Typography>
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Reason
          </Typography>
          <Typography component="div" variant="p" width={"500px"}>
            {item.reason}
          </Typography>
        </CardContent>

      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3  }}>

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div" >
          Comment
          </Typography>
          <Typography component="div" variant="p" width={"100px"}>
           {item.comment}
          </Typography>
          
        </CardContent>

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
         Status
          </Typography>
          <Typography component="div" variant="p">
           {item.status }
          </Typography>
        </CardContent>
       
      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
      
      
       

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          PNR
          </Typography>
          <Typography component="div" variant="p">
          { item.pnr}
          </Typography>
          
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
         Mobile
          </Typography>
          <Typography component="div" variant="p">
            {item.mobile}
          </Typography>
        </CardContent>
      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
      
      
       

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          order Id
          </Typography>
          <Typography component="div" variant="p">
          { item.orderId}
          </Typography>
          
        </CardContent>
     
      </Box>

<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
       

        <CardContent sx={{ flex: '1 0 auto' }}>
         
          <button
                  className="btn btn-primary"
               
                  data-dismiss="modal"
                >Ok  <i className="fa fa-check"></i>
                </button>
        </CardContent>

      </Box>
</Card>
 </>
}