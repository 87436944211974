import { Formik, Form } from 'formik'
import React from 'react'
import { useAddItem, useCusineType, useItemType, useUpdateItem, useViewitem } from '../hooks/item'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import Newselect from '../Common/Newselect'
import TextField from '../Common/MuiTextField'



const AddItemForm = ({ additemModal, setAddItemModal}) => {
const {handleAddItem}=useAddItem()
    const { itemTypeData } = useItemType()
    console.log(itemTypeData)
    const { cusineTypeData } = useCusineType()
    const {id}=useParams()
   
    const validate = Yup.object({
        itemName: Yup.string().min(2).max(25).required("Please enter Item Name"),
        category: Yup.string().required("Please choose category"),
        gst: Yup.string().required("Please enter GST"),
        description: Yup.string().required("Please enter description"),
        isRecomended: Yup.string().required("Please choose recommended"),
        yrPrice: Yup.string().required("Please enter price"),
        basePrice: Yup.string().required("Please enter price")
    })

    const handleSubmit = async (values) => {
        console.log("submit", values)
        const res = await handleAddItem(values)
        console.log("res", res)
        if (res.success) {
            toast.success("Item Added")
            setAddItemModal(false)
        }
        else {
            toast.error(res)
        }
    }
    return (
        <div>
            <Formik
                initialValues={{

                    resturant: id,
                    itemName: "",
                    description: "",
                    festiveStartDate: "",
                    festiveEndDate: "",
                    opening_time: "",
                    closing_time: "",
                    isFeatured: true,
                    isFestiveItem: true,
                    isBestSelling: true,
                    isRecomended: true,
                    yrPrice: "",
                    basePrice: "",
                    gst: "",
                    itemType: ""  ,
                    cusineType:"",
                    category: "",
          
                }}
                validationSchema={validate}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form>

                        <div className="row">
                            <div className='col-md-12 '>
                                <TextField
                                    label="Item Name"
                                    name="itemName"
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mt-3'>
                                <TextField
                                    label="Description"
                                    name="description"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <TextField
                                    label="Cost Price"
                                    name="basePrice"
                                />
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <TextField
                                    label="Yatri Restro Price"
                                    name="yrPrice"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-3 '>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Item Type</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="itemType"
                                            value={formik.values.itemType}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Item Type" />}
                                        >
                                            {itemTypeData?.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.food_type}> {element.food_type}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Cusine Type</InputLabel>
                                        <Newselect

                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="cusineType"
                                            value={formik.values.cusineType}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Cusine Type" />}
                                        >
                                            {cusineTypeData?.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.cusineValue}> {element.cusineName}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Resturant Type</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="category"
                                        value={formik.values.category}
                                        onChange={formik.handleChange}
                                        size="small"
                                        input={<OutlinedInput label="Resturant Type" />}
                                    >
                                        <MenuItem value="veg">Veg</MenuItem>
                                        <MenuItem value="nonVeg">Non-Veg</MenuItem>
                                    </Newselect>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Featured Item</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="isFeatured"
                                        value={formik.values.isFeatured}
                                        onChange={formik.handleChange}
                                        size="small"
                                        input={<OutlinedInput label="Featured Item" />}
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Newselect>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Festive Item</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="isFestiveItem"
                                        value={formik.values.isFestiveItem}
                                        onChange={formik.handleChange}
                                        size="small"
                                        input={<OutlinedInput label="Festive Item" />}
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Newselect>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Best Selling</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="isBestSelling"
                                        value={formik.values.isBestSelling}
                                        onChange={formik.handleChange}
                                        size="small"
                                        input={<OutlinedInput label="Best Selling" />}
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Newselect>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Recomended</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="isRecomended"
                                        value={formik.values.isRecomended}
                                        onChange={formik.handleChange}
                                        size="small"
                                        input={<OutlinedInput label="Recommended" />}
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Newselect>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <TextField
                                    label="GST"
                                    name="gst"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <TextField
                                    type="date"
                                    name="festiveStartDate"
                                    label="Festive Start Date "
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <TextField
                                    type="date"
                                    name="festiveEndDate"
                                    label="Festive End Date "
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-md-6 mt-3'>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            fullWidth
                                            value={moment(formik.values.opening_time, "HH:mm")}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("opening_time", value.format("HH:mm"))}
                                            name="opening_time" label="Opening Time" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            fullWidth
                                            value={moment(formik.values.opening_time, "HH:mm")}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("opening_time", value.format("HH:mm"))}
                                            name="opening_time" label="Opening Time" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-success className='col-md-6 mt-3 '" type="submit">
                                Add
                            </button>
                        </div>


                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default AddItemForm
