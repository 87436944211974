import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import UpdateResturantForm from "../form/UpdateResturantForm";


export default function UpdateRestaurantModalModal({data, updateModal, setUpdateModal }) {
   
    const style = {
        position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '75%',
    overflow: "scroll",
    height: "90vh",    
      };

      const handleSubmit=async(values,resetForm)=>{
        

      }
      
    return (
        <>
        <Modal
            open={updateModal}
        >
            <Box sx={style}>
            <div className="container-fluid modalbody">
                <div className="d-flex justify-content-between">
                    <h5 className="m-3">Update Restaurant</h5>
                    <IconButton onClick={() => setUpdateModal(false)}>
                        <CloseIcon />
                    </IconButton>

                </div>
                <div>
                    <UpdateResturantForm updateModal={updateModal} setUpdateModal={setUpdateModal}data={data} handleSubmit={handleSubmit}/>
                </div>
            </div>
            </Box>
        </Modal>
        </>
    )
}




