import React, { useState } from 'react'
import DataTable from '../../components/Common/DataTableBase';
import Loader from '../../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import FilterComponent from '../../components/Common/FilterComponent';
import { OutlinedButton } from '../../components/Common/Button';
import { useRestaurantsList } from '../hooks/restaurants';
import Datagrid from '../Common/Datagrid';
import AddResturantModal from '../model/AddResturantModal';


export default function ResturantComp() {
    let navigate = useNavigate();
    const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    // console.log(restaurantsData)

const arr = restaurantsData || []
const active = arr.filter(function(item)
{
     return item.status ==='Active';
});


    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    /*  const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            cell: row => <Link to={`/restaurants/${row._id}`}>{row.name}</Link>,
            sortable: true,
            minWidth: "200px"
        },
        {
            name: 'Mobile',
            selector: row => row.contact_details.contact_number,
        },
        {
            name: 'Station Name',
            // selector: row => row.station[0].stationName,
            selector : row => row.station.map((e,i)=>{return <span>{e.stationName} ,</span> }) ,
            sortable: true,
            minWidth: "250px"
        },
        {
            name: 'Station Code',
             selector : row => row.station.map((e,i)=>{return <span>{e.stationCode} ,</span> })
        },
        {
            name: 'Status',
             selector : row => row.status === "Active" ?  <span className='badge badge-success'>Active</span>: <span className='badge badge-danger'>Closed</span>,
             sortable :true
            },
    
    ];
    */

    //New UI
    const [modalIsOpen, setModalIsOpen] = useState(false);


    const detailsRows = restaurantsData?.map((row) => {
      return {
       
        id: row._id,
        name: row.name,
        mobile: row.contact_details.contact_number,
        station: row.station.map((u) => u.stationName).join(", "),
        stationcode: row.station.map((u) => u.stationCode).join(", "),
        status: row.status,
        wallet: row.wallet.$numberDecimal
      };
    })
    
  
    const columns = [   
    
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 170, flex: 1,
        renderCell: (params) => (          
                 <a className='text-decoration-none text-black' href={`/restaurants/${params.row.id}`}>{params.row.name}</a>
        )
    },
    { field: 'mobile', headerName: 'Mobile', flex:0.5,minWidth: 100, },
    {
      field: 'station',
      headerName: 'Station',
      minWidth: 170, flex: 1,
      renderCell: (params) => (
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%',}}>
              <div style={{ height: '15px', marginBottom: '5px' }}> {params.row.station}</div>
              
          </div>
      )
  },
  { field: 'stationcode', headerName: 'Station Code', flex:0.5,minWidth: 100, },
      { field: 'status', headerName: 'Status', flex:0.5,minWidth: 100, },
      { field: 'wallet', headerName: 'Balance',flex:0.5 ,minWidth: 100,},
  
    ];

    if (restaurantsIsLoading) {
        return <Loader />
    }

    // Filter data
    const filteredItems =restaurantsData.length>0 ? restaurantsData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    ) : [];
    return (
        <>
        <div className="content-wrapper">

        <div className="container-fluid">

            
        <div className="card pd-50 pd-sm-70" style={{padding:"20px"}}>
        <h6 className="card-body-title">All Restaurants</h6>
            <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>
            


        <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Resturants</span>
                  <span className="info-box-number">{arr.length}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-shopping-cart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Active Restaurants</span>
                  <span className="info-box-number">{active.length}</span>
                </div>
              </div>
            </div>




            <div className="clearfix hidden-md-up" />
          
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">InActive Restaurants</span>
                  <span className="info-box-number">
                    {arr.length - active.length}
                  </span>
                </div>
              </div>
            </div>

      
          </div>
          <div className="pb-2"><OutlinedButton label='Add new restaurant' onClick={() => setModalIsOpen(true)}/>
            <AddResturantModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
            /></div>    
              <Datagrid   data={restaurantsData} columns={columns} detailsRows={detailsRows}    isLoading={restaurantsIsLoading}/> 
{/* 
            <OutlinedButton className='col-2' label='Add new restaurant' onClick={() => navigate('/restaurants/add')}/>
            <div className="card-body p-20">
              
            <div className="table-wrapper">
                
                {
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        progressPending={restaurantsIsLoading}
                        // conditionalRowStyles={conditionalRowStyles}
                        
                        subHeaderAlign="right"
                        subHeaderWrap
                        subHeaderComponent={subHeaderComponent}
                        subHeader
                        />
                      }
            </div>
                      </div>
                     */} 
        </div>
        </div>    </div>

   
        </>
    )
}
