import React, { useState } from 'react'
import { useItemList } from '../hooks/item'
import { Box } from '@mui/material';

import { ViewItemModal } from '../model/ViewItemModel';
import { endpoints } from '../../config/endpoints';
import { UpdateItemModal } from '../model/UpdateItemModel';
import { DeleteItemModal } from '../model/DeleteItemModel';
import Datagrid from '../Common/Datagrid';

const ViewItem = ({ id }) => {
  const { itemData, itemIsLoading } = useItemList(id)
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [itemModal,setItemModal]=useState(false)
  const [updateModal,setUpdateModal]=useState(false)
  const [deleteModal,setDeleteModal]=useState(false)
  
  const handleButtonClick = (id) => {
    console.log('view ID',id)
    setSelectedRowId(id);
    setItemModal(true)
  };

  const handleUpdateClick=(id)=>{
    setSelectedRowId(id);
    setUpdateModal(true)
  }
  const handleDeleteClick=(id)=>{
    console.log(' delete ID',id)
    setSelectedRowId(id);
    setDeleteModal(true)
  }
 
  
  const detailsRows = itemData?.map((row) => {
    return {

      id: row._id,
      name: row.itemName,
      type: row.itemType,
      category: row.category,
      availability: row.availability,
      basePrice: row.basePrice.$numberDecimal,
     

    };
  })



  const columns = [
  
    { field: 'name', headerName: ' Item Name', flex: 0.5,minWidth:150 },
    { field: 'type', headerName: 'Food Type', flex: 0.3 ,minWidth:100},
    {
      field: 'category', headerName: 'Veg/Non-Veg' , minWidth:100 , flex: 0.2,
      renderCell: (params) => {
        return (
          <>
            {params.row.category == "nonVeg" ? ( <span className='badge badge-danger'>Non-Veg</span>)
              : (<span className='badge badge-success'>Veg</span>)}
          </>
        )
      }

    },
    { field: 'availability', headerName: 'Availability',minWidth:100 , flex: 0.2,
    renderCell:  (params) => {
      return (
        <>
          {params.row.availability === true ? <span>Yes</span> :<span>No</span>}
        </>
      )
    },
     },
    { field: 'basePrice', headerName: 'Price', flex: 0.2,minWidth:100 ,
    renderCell: (params) => {
      return (
        <>
          Rs. {params.row.basePrice}
        </>
      )
    }
     },
     { field: 'view', headerName: 'View', flex: 0.2,
    renderCell: (params) => {
       const id = params.row.id;
      return (
        <>
         <button  type="button" class="btn btn-transparent"  onClick={() => handleButtonClick(id)} ><i className="fa fa-eye"></i></button>
        </>
      )
    }
     },
    
     { field: 'update', headerName: 'Update', flex: 0.2,
     renderCell: (params) => {
      const id = params.row.id;
      
       return (
         <>
         <button  type="button" class="btn btn-transparent" onClick={()=>handleUpdateClick(id)}> <i class="far fa-edit"></i></button>
         </>
       )
     }
      },
     
      { field: 'delete', headerName: 'Delete', flex: 0.2,
      renderCell: (params) => {
         const id = params.row.id;
        return (
          <>
          <button  type="button" class="btn btn-danger" onClick={()=>handleDeleteClick(id)}>  <i class="fas fa-trash"></i></button>
          </>
        )
      }
       },
  ];
  

  
  return (
    <div>
      <Box >
        <Datagrid isLoading={itemIsLoading} columns={columns}  detailsRows={detailsRows}   checkboxSelection />
      </Box>
      <ViewItemModal itemModal={itemModal} setItemModal={setItemModal} id={selectedRowId} />
      <UpdateItemModal updateModal={updateModal} setUpdateModal={setUpdateModal}  id={selectedRowId}/>
      <DeleteItemModal  deleteModal={deleteModal} setDeleteModal={setDeleteModal}  id={selectedRowId}/>
    </div>
  )
}


export default ViewItem
