import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import FoodTypeCont from '../../components/FoodTypeComp/FoodTypeCont';

const FoodType = () => {
    const { user } = useUser();

    return (
      <div>
        <Header user={user} />
        <Sidenav />
        <div className="content-wrapper">
<FoodTypeCont/>
        </div>
      </div>
    );
  };

export default FoodType;