import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { endpoints } from "../../config/endpoints";
import { API_HOST_URL } from "../../config";


const ITEM_QUERY_KEY = 'items'
const ITEM_QUERY_KEY2 = 'items2'
const ITEM_QUERY_KEY3 = 'items3'
const ITEM_QUERY_KEY_ITEM='itemdetail'

export function useAddItem() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.item.add}`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleAddItem: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([ITEM_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        addItemIsLoading: mutation.isLoading,
    }
}


export function useUpdateItem(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.item.update}/${id}`, {
		method: 'PUT',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleUpdateItem: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([ITEM_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}




export function useItemList(id) {
    const { isLoading, data } = useQuery([ITEM_QUERY_KEY], async () => {
        try {
            const { data } = await fetchJson(`${API_HOST_URL}/api/v1/resturant/${id}/item`)
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { itemData: data, itemIsLoading: isLoading }
}



export function useViewItem(id) {
   
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.item.getDetail}/${id}`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleViewItem: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([ITEM_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleViewIsLoading: mutation.isLoading,
    }
}


//Added by Saurav
export function useViewitem(id) {
    const { data ,isLoading} = useQuery({
        queryKey: [ITEM_QUERY_KEY_ITEM],
        queryFn: async () => {
            try {
                const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.item.getDetail}/${id}`)
                return data
            } catch (error) {
                return error
            }
        }, cacheTime: 0, staleTime: 1
    })
    return { item: data ,itemdataIsLoading:isLoading}
}



export function useCusineType() {
    const { isLoading, data } = useQuery([ITEM_QUERY_KEY2], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/api/v1/cusine`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { cusineTypeData: data, cusineIsLoading: isLoading }
}






export function useItemType() {
    const { isLoading, data } = useQuery([ITEM_QUERY_KEY3], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/api/v1/food-type`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { itemTypeData: data, itemTypeIsLoading: isLoading }
}








const DeleteItem =async (id)=>{
    const { accessToken } = getTokens();
  await  fetch(`${API_HOST_URL}/${endpoints.item.update}/${id}`, { method: 'DELETE' ,
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}` 
  }
  
  })
//   window.location.reload()
}




