import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddDiscount from "../form/AddDiscount";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 500,
};


export function AddDiscountModal({ discountModal,setDiscountModal }) {

    return (
        <div>
            <Modal
                open={discountModal}
                onClose={() => setDiscountModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Add Discount</h5>
                            <IconButton onClick={() => setDiscountModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                         <AddDiscount setDiscountModal={setDiscountModal}/>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}