import { Box, Card, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'


import Button from '@mui/material/Button';
import {UpdateOrderStatus} from "../model/UpdateOrderStatus"
import { useConfirm, useUpdateOrderStatus } from '../hooks/orders'
import { toast } from 'react-toastify'
import { ChangeResModal } from '../model/ChangeResModel'
import { NewOrderStatus } from '../model/NewOrderStatus'
import { OrderlogModal } from '../model/OrderlogModal'
import DataTable from '../Common/DataTable';
import  "../../Styling/Order.css"

const OrderInformation = ({ data }) => {
    const[newstatusmodal,setNewstatusmodal]=useState(false)
    const [statusModal,setStatusModal]=useState(false)
    const [changeresModal,setChangeResModal]=useState(false)
    const [logmodal,setLogmodal]=useState(false)

const { handleUpdateOrder, handleUpdateIsLoading } = useUpdateOrderStatus(data._id);

const {handleConfirm , isConfirmLoading} = useConfirm();

const confirmOrderFun = async (id) => {
    const data = await handleConfirm(id);
    if(data.success){
      toast.success("Order Confirmed Successfully")
    }else{
      toast.error(data.error);
    }
  }



    const navigate = useNavigate()

      
    
    const detailsRows = data?.orderItems.map((row) => {
        return {
            id: row._id,
            itemName: row.itemName,
            quantity: row.quantity,
            baseRate: row.baseRate.$numberDecimal ,
            amount:  row.baseRate.$numberDecimal * row.quantity ,
            discount:row.discount.$numberDecimal,
            tax:row.taxRate.$numberDecimal* row.quantity,
            totalamount: row.baseRate.$numberDecimal * row.quantity ,
         
           
        };
    })


    const columns = [
        {
            field: 'itemName', headerName: 'Item Name', flex: 1, minWidth: 150,
        },
        { field: 'quantity', headerName: 'Quantity', minWidth: 100, flex: 0.5 },
        { field: 'baseRate', headerName: 'Rate', minWidth: 100, flex: 0.5},
        { field: 'amount', headerName: 'Amount', flex: 0.5 },
        { field: 'discount', headerName: 'Discount', flex: 0.5 },
        { field: 'tax', headerName: 'Tax', flex: 0.5 },
        { field: 'totalamount', headerName: 'Total Amount', flex: 0.5,renderCell: (params) =>
            <a>{parseFloat(params.row.totalamount)+parseFloat(params.row.tax)}</a> },
       
    ];
const gst=Math.ceil(`${data.gst.$numberDecimal}`)
const totalAmount=Math.ceil(`${data.totalAmount.$numberDecimal}`)

    return (
        <div className='content-wrapper'>
            <Card sx={{ padding: "10px" }}>
                <div className='d-flex justify-content-between p-1 px-3'>
                    <div className='d-flex justify-content-between 'style={{gap:"5px"}}>
                    <Button variant="outlined" onClick={() => navigate("/order")}>Back To Orders</Button>
                    <Button variant="outlined" onClick={() => confirmOrderFun(data._id)}>{isConfirmLoading ? "Loading..." : "Confrim Order"}</Button>
                    <Button variant="outlined" onClick={()=>setLogmodal(true)}>View Log</Button>
                    </div>
                    <div className='d-flex justify-content-between' style={{gap:"5px"}}>
                    <Button variant="outlined" onClick={()=>setNewstatusmodal(true)}>Status</Button>
                    <Button variant="outlined" onClick={()=>setChangeResModal(true)}>Change Restaurant</Button>
                    <Button variant="outlined" onClick={()=>setStatusModal(true)}>IRCTC Status</Button>
                    </div>
                </div>
                <div className='row p-3'>
                    <div className='col-6'>
                        <div className='col-md-12 p-1 mb-2'>
                            Order ID : <span className="item-details">#{data.orderId} </span>
                        </div>
                        <div className='col-md-12 p-1 '>
                        <i class="fas fa-store-alt"></i>  <span className="item-details">{data.resturant.name} </span>
                        </div>
                        <div className='col-md-12 p-1 mb-2'>
                        <i class="fas fa-phone-alt"></i>  <span className="item-details">{data.resturant.contact_details.contact_number}</span>
                        </div>
                     
                        <div className='col-md-12 p-1'>
                            Booking Date : <span className="item-details">{moment(data.createdAt).format("DD-MM-YYYY hh:mm A")} </span>
                        </div>
                        <div className='col-md-12 p-1 '>
                            Order Booking Date ETA : <span className="item-details"> </span>
                        </div>
                        <div className='col-md-12 p-1'>
                            Delivery Date(ETA) :  <span className="item-details"></span>
                        </div>
                        <div className='col-md-12 p-1 mb-3'>
                           Status :  <span className="item-details">{data.status}</span>
                        </div>
                    </div>
                    <div className='col-6  '>

                        <div className='order-details-float'>
                            <div className='col-md-12 p-1 mb-3 '>
                                <span className='order-details-float' >  <span className="item-details"> Customer Details  </span>
                                </span>
                            </div>
                            <div className='col-md-12 p-2 mb-3 '>
                                <span className='order-details-float' >  <i class="fas fa-user"></i>  <span className="item-details"> {data.deliveryDetails.passengerName} </span>
                                </span>
                            </div>
                            <div className='col-md-12 p-2 mb-3 '>
                                <span className='order-details-float' >  <i class="fas fa-phone-alt"></i>  <span className="item-details"> {data.mobile} </span>
                                </span>
                            </div>
                            <div className='col-md-12 p-2 mb-3 '>
                                <span className='order-details-float '>  PNR : <span className="item-details "> {data.deliveryDetails.pnr}</span>
                                </span>
                            </div>
                            <div className='col-md-12 p-2 mb-3 '>
                                <span className='order-details-float ' >  Coach/Berth : <span className="item-details ">{data.deliveryDetails.coach} /{" "}{data.deliveryDetails.berth}</span>
                                </span>
                            </div>
                            <div className='col-md-12 p-2 mb-3  '>
                                <span className='order-details-float' > <i class="fas fa-warehouse"></i>  <span className="item-details ">{data.deliveryDetails.station} /{" "}{data.deliveryDetails.stationCode}</span></span>

                            </div>
                            <div className='col-md-12 p-2 mb-3 '>
                                <span className='order-details-float' >   <i class="fas fa-train"></i>  <span className="item-details "> {data.deliveryDetails.trainName} /{" "}{data.deliveryDetails.trainNo}</span></span>

                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <DataTable rows={detailsRows} columns={columns} />

                </div>
                <div className='col-md-6 order-details-float'>
                    <div className='p-2'>
                        Payment:<span className='order-details-float item-details '> {data.paymentType === "CASH_ON_DELIVERY" ? ("Cash On Delivery") : ("Online")}</span>
                    </div>
                    <div className='p-2'>
                        Total Amount :<span className='order-details-float item-details '>Rs. {data.totalBase.$numberDecimal}</span>
                    </div>
                    <div className='p-2' >
                       Total tax :<span className='order-details-float item-details '>Rs. {gst}</span>
                    </div>
                    <div className='p-2' >
                       Net Payable :<span className='order-details-float item-details '>Rs. {totalAmount}</span>
                    </div>
                </div>
                <NewOrderStatus newstatusmodal={newstatusmodal} data={data} setNewstatusmodal={setNewstatusmodal}/>
                <UpdateOrderStatus statusModal={statusModal} setStatusModal={setStatusModal} data={data}  handleUpdateOrder={handleUpdateOrder} handleUpdateIsLoading={handleUpdateIsLoading} />
           <ChangeResModal data={data} openModal={changeresModal} setOpenModal={setChangeResModal}/>
           <OrderlogModal openModal={logmodal} setOpenModal={setLogmodal} data={data}/>
            </Card>
        </div>
    )
}

export default OrderInformation

