import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'

import { toast } from 'react-toastify'
import Newselect from '../Common/Newselect'
import TextField from '../Common/MuiTextField'
import SubmitButton from '../Common/Button'
import * as Yup from 'yup'

const UpdateOrderStatusForm = ({ data, handleUpdateOrder, handleUpdateIsLoading ,setStatusModal}) => {

    const ROLES_OPTIONS = [
        { 'label': 'ORDER PREPARING', value: 'ORDER_PREPARING' },
        { 'label': 'ORDER PREPARED', value: 'ORDER_PREPARED' },
        { 'label': 'ORDER OUT FOR DELIVERY', value: 'ORDER_OUT_FOR_DELIVERY' },
        { 'label': 'ORDER DELIVERED', value: 'ORDER_DELIVERED' },
        { 'label': 'ORDER CANCELLED', value: 'ORDER_CANCELLED' },
        { 'label': 'ORDER UNDELIVERED', value: 'ORDER_UNDELIVERED' },
        { 'label': 'ORDER PARTIALLY DELIVERED', value: 'ORDER_PARTIALLY_DELIVERED' },
    ]

    const ORDER_CANCELED_REASON = [
        { 'label': 'BEYOND SERVICE HOUR', value: 'BEYOND_SERVICE_HOUR' },
        { 'label': 'TRAIN DELAYED', value: 'TRAIN_DELAYED' },
        { 'label': 'LAW N ORDER', value: 'LAW_N_ORDER' },
        { 'label': 'NATURAL CALAMITY', value: 'NATURAL_CALAMITY' },
        { 'label': 'PASSENGER JOURNEY CANCELLED', value: 'PASSENGER_JOURNEY_CANCELLED' },
    ]

    const ORDER_UNDELIVERED_REASON = [
        { 'label': 'CUSTOMER NOT AVAILABLE', value: 'CUSTOMER_NOT_AVAILABLE' },
        { 'label': 'OTP MISMATCH', value: 'OTP_MISMATCH' },
        { 'label': 'VENDOR INABILITY', value: 'VENDOR_INABILITY' },
        { 'label': 'VENDOR CLOSED', value: 'VENDOR_CLOSED' },
        { 'label': 'CUSTOMER DENIED', value: 'CUSTOMER_DENIED' },
    ]

    const updateStatus = async (values) => {
        const res = await handleUpdateOrder(values)
        console.log(res)
        if (res.success) {
            setStatusModal(false)
            toast.success("Order Status Updated Successfully");
        } else {
            toast.error(res.error);
        }

    }
    const validate = Yup.object({
        status: Yup.string()
            .required('Select Status.')
    })


    return (
        <div>
            <Formik
                initialValues={{
                    status: data ? data.status : "",
                    deliveryBoy: data && data.deliveryBoy ? data.deliveryBoy : "",
                    deliveryBoyMob: data && data.deliveryBoyMob ? data.deliveryBoyMob : "",
                    remarks: data && data.remarks ? data.remarks : "",
                    additionalRemarks: "Ok",
                    otp: "1234",
                    orderItems: [],
                }}
                onSubmit={updateStatus}
                validationSchema={validate}
                enableReinitialize
                >
                {(formik) => (
                    <Form>
                        <div className='row'>
                            <div className='col-md-12  '>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Order Status</InputLabel>
                                        <Newselect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                            size="small"
                                            input={<OutlinedInput label="Order Status" />}
                                        >
                                            {ROLES_OPTIONS.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            
                                {
                                    formik.values.status == 'ORDER_OUT_FOR_DELIVERY' && (<>
                                        <div className="col-md-12 mt-3">
                                            <TextField name="deliveryBoy" label="Delivery Boy Name" />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <TextField name="deliveryBoyMob" label="Delivery Boy Mobile No." />
                                        </div>
                                    </>)
                                }
                                {
                                    formik.values.status == 'ORDER_DELIVERED' && (<>
                                        <div className="col-md-12 mt-3">
                                            <TextField name="otp" label="Enter OTP" />
                                        </div>
                                    </>)
                                }
                                {
                                    formik.values.status == 'ORDER_UNDELIVERED' && (<>
                                        <div className='col-md-12 mt-3  '>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Reason</InputLabel>
                                                    <Newselect
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="remarks"
                                                        value={formik.values.remarks}
                                                        onChange={formik.handleChange}
                                                        size="small"
                                                        input={<OutlinedInput label="Reason" />}
                                                    >
                                                        {ORDER_UNDELIVERED_REASON.map((element, id) => {
                                                            return (
                                                                <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Newselect>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </>)
                                }
                                {
                                    formik.values.status == 'ORDER_CANCELLED' && (<>
                                        <div className='col-md-12 mt-3  '>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Reason</InputLabel>
                                                    <Newselect
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="remarks"
                                                        value={formik.values.remarks}
                                                        onChange={formik.handleChange}
                                                        size="small"
                                                        input={<OutlinedInput label="Reason" />}
                                                    >
                                                        {ORDER_CANCELED_REASON.map((element, id) => {
                                                            return (
                                                                <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Newselect>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </>)
                                }
                                {
                                    formik.values.status == 'ORDER_PARTIALLY_DELIVERED' && (<>
                                        <div className='col-md-12 mt-3  '>
                                            {data.orderItems.map((value, i) => {
                                                return (
                                                    <div key={i}>
                                                        <FormGroup>
                                                            <FormControlLabel control={<Checkbox onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    formik.values.orderItems.push({ "itemId": value.item, "quantity": Number(value.quantity) })
                                                                } else {
                                                                    formik.values.orderItems = formik.values.orderItems.filter((val) => val.itemId !== value.item)
                                                                }
                                                            }} name={value.item} />
                                                            } label={value.itemName} />
                                                            <div className='mt-3 mb-3'>
                                                                <input className='form-control' type='' onChange={(e) => {
                                                                    for (let i = 0; i < formik.values.orderItems.length; i++) {
                                                                        if (formik.values.orderItems[i].itemId === value.item) {
                                                                            formik.values.orderItems[i].quantity = Number(e.target.value);
                                                                        }
                                                                    }
                                                                }} placeholder="Quantity" size="small" />
                                                            </div>
                                                        </FormGroup>

                                                    </div>
                                                )
                                            })}
                                           
                                                <TextField name="otp" label="Enter OTP" />
                                          
                                        </div>
                                    </>)
                                }
                             <div className="col-md-12 mt-3">
                            <TextField name="additionalRemarks" label="Additional Remarks" />
                        </div>

                        <div className=' col-12 mt-3 '>
                            <SubmitButton className="float-end " isLoading={handleUpdateIsLoading} label="Update" />
                        </div>
                        <div>

                            <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.remarks && formik.touched.remarks ? formik.errors.remarks : null}</span>
                        </div>
                        </div>
                       

                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default UpdateOrderStatusForm
