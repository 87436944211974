import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { CollectionsOutlined } from "@mui/icons-material";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,

};




export function DeleteItemModal({ deleteModal, setDeleteModal, id }) {
  const ITEM_QUERY_KEY = 'items'
  
  const queryClient = useQueryClient()
  const DeleteItem = async (id) => {
    const { accessToken } = getTokens();
    await fetch(`${API_HOST_URL}/${endpoints.item.update}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken}`,
      },
    });
  };

  const mutation = useMutation(DeleteItem, {
    onSuccess: () => {
      queryClient.invalidateQueries([ITEM_QUERY_KEY]);
      toast.success("Item Deleted")
      setDeleteModal(false)
    },
  });

  const handleDelete = (itemId) => {
    mutation.mutate(itemId);
  };

 


  return (
    <div>
      
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <div className="container-fluid modalbody">
            <div className="d-flex p-2 ">
              Are you sure you want to delete this item?
            </div>
            <div className="d-flex p-2  justify-content-end" style={{gap:"5px"}}>
              <button className="btn btn-danger  " onClick={() => handleDelete(id)}>
                Yes
              </button>
              <button className="btn btn-success " onClick={() => setDeleteModal(false)}>
                No
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )

}