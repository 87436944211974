import { Formik } from 'formik';
import React, { useRef } from 'react'
import { toast } from 'react-toastify';
import TextField from '../Common/MuiTextField';
import * as Yup from "yup";
import {  useFoodTypeItem } from '../hooks/extraHook';


const AddFoodForm = () => {
    const closeref = useRef();
    const {handleAddFoodType} =useFoodTypeItem()


    const signUpSchema = Yup.object({
        food_type: Yup.string().required("Please enter Food Type"),
      
      });
    
      const sendData = async (data, { resetForm }) => {
        const res = await handleAddFoodType(data);
        if (res.success) {
          toast.success("Cusine Item Updated");
          resetForm();
          closeref.current.click();
        } else {
          toast.error(res.error);
        }
      };
    
      return (
        <>
          <Formik
            initialValues={{
                food_type: "",         
            }}
            validationSchema={signUpSchema}
            onSubmit={sendData}
            // onSubmit={(val)=>console.log(val)}
            enableReinitialize
          >
            {(formik) => (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Item
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeref}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={formik.handleSubmit} className="form-row">
            <div className="form-group col-md-6">
                      <TextField
                        label="Food Type"
                        name="food_type"
                        placeholder="Food Type"
                        sx={{ mb: 3 }}
                      />
                    </div>
                   
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button className="btn btn-success" type="submit">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </>
      );
    };

export default AddFoodForm