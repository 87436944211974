import React from 'react'
import { Form, Formik } from "formik";
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, LinearProgress, MenuItem, OutlinedInput, Stack, Typography, createFilterOptions } from '@mui/material';
import { stripStationsList, useStationsList } from '../hooks/stations';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import * as Yup from 'yup'
import moment from 'moment';
import { useUpdateRest } from '../hooks/restaurants'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import SubmitButton from "../Common/Button"
import TextField from '../Common/MuiTextField';
import Newselect from '../Common/Newselect';

const UpdateResturantForm = ({ data, setUpdateModal }) => {

    const { id } = useParams()
    const { handleUpdateRest } = useUpdateRest(id)
    const handleSubmit = async (values) => {
        const res = await handleUpdateRest(values)

        if (res.success) {
            toast.success("Restaurant Updated")
            setUpdateModal(false)
            console.log("updated")
        } else {
            toast.error(res.error)
            console.log(res.error)
        }
    }
    const { stationsData, stationsIsLoading } = useStationsList();
    const updateSchema = Yup.object({
        name: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter restaurant name."),
        brand: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter brand name."),
        address: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter address."),
        city: Yup.string().trim("Remove Spaces").strict(true).required("Enter city name"),
        state: Yup.string().trim("Remove Spaces").strict(true).required("Enter state name"),
        pinCode: Yup.number().required("Enter pincode"),
        station_distance: Yup.string().trim("Remove Spaces").strict(true).min(1).required("Enter station distance."),
        firm_name: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter company name."),
        firm_type: Yup.string().trim("Remove Spaces").strict(true).required("Enter Firm Type."),
        delivery_type: Yup.string().required("Enter Delivery Type."),
        company_pan: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter company pan."),
        gstin: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter gst number."),
        fssai_lic_num: Yup.string().trim("Remove Spaces").strict(true).required("Enter fssai licence number."),
        closing_time: Yup.string().required("Enter closing time."),
        opening_time: Yup.string().required("Enter opening time."),
        min_order_amount: Yup.string().trim("Remove Spaces").strict(true).required("Enter Minimum Order Amount."),
        min_order_time: Yup.string().trim("Remove Spaces").strict(true).required("Enter Minimum Order Time."),
        contact_details: Yup.object().shape({
            contact_person: Yup.string().trim("Remove Spaces").strict(true).required("Enter contact person."),
            contact_number: Yup.string().trim("Remove Spaces").strict(true).required("Enter contact number."),
            email: Yup.string().email().trim("Remove Spaces").strict(true).required("Enter email address."),
        }),
        bank_ac_details: Yup.object().shape({
            ac_holder_name: Yup.string().trim("Remove Spaces").strict(true).required("Enter Account Holder name"),
            account: Yup.string().trim("Remove Spaces").strict(true).required("Enter Accout No."),
            ifsc: Yup.string().trim("Remove Spaces").strict(true).required("Enter IFSC"),
        }),
        fssaiValidUpto: Yup.date().required('Enter date')
    });



    return (
        <>
            <Formik
                initialValues={{
                    name: data?.name,
                    brand: data?.brand,
                    station_distance: data?.station_distance,
                    station: data?.station.stationName,
                    address: data?.address,
                    city: data?.city ? data?.city : "",
                    state: data?.state ? data?.state : "",
                    pinCode: data?.pinCode ? data?.pinCode : "",
                    firm_name: data?.firm_name,
                    firm_type: data?.firm_type,
                    company_pan: data?.company_pan,
                    gstin: data?.gstin,
                    contact_details: {
                        contact_person: data.contact_details.contact_person,
                        email: data.contact_details.email,
                        contact_number: data.contact_details.contact_number,
                        alt_number: data.contact_details.alt_number,
                    },
                    restaurant_type: data?.restaurant_type,
                    delivery_type: data?.delivery_type,
                    fssai_status: data.fssai_status,
                    fssai_lic_num: data?.fssai_lic_num,
                    status: data?.status,
                    min_order_amount: data?.min_order_amount ? data?.min_order_amount.$numberDecimal : "",
                    min_order_time: data?.min_order_time,
                    opening_time: data.opening_time,
                    closing_time: data.closing_time,
                    bank_ac_details: {
                        ifsc: data.bank_ac_details.ifsc,
                        account: data.bank_ac_details.account,
                        ac_holder_name: data.bank_ac_details.ac_holder_name,
                    },

                    fssaiValidUpto: data?.fssaiValidUpto ? moment(data?.fssaiValidUpto).format('yyyy-MM-DD') : ""
                }}

                validationSchema={updateSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form  >
                        <div className="row-mt-4">
                            <div className='col-md-12'>
                                <Autocomplete
                                    disablePortal
                                    size='small'
                                    id="combo-box-demo"
                                    loading={stationsIsLoading}
                                    slots={{ loadingOverlay: LinearProgress, }}
                                    options={stationsData?.map(stripStationsList)}
                                    getOptionLabel={(option) => option.label}
                                    value={formik.values.station?.value}
                                    onChange={(e, value) => formik.setFieldValue("station", value.value)}
                                    slotProps={{ TextField: { size: 'small' } }}
                                    renderInput={(params) => <TextField {...params} name="station" label="Station" />}
                                    isOptionEqualToValue={(options, value) => options.value === value.value}
                                />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="Resturant Name *"
                                    name="name"
                                />
                            </div>
                            <div className="col-md-3 mt-4  mt-md-0  ">
                                <TextField
                                    label="Brand Name *"
                                    name="brand"
                                />
                            </div>
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="Firm Name *"
                                    name="firm_name"
                                />
                            </div>
                            <div className="col-md-3 mt-4  mt-md-2 ">

                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Firm Type</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="firm_type"
                                            value={formik.values.firm_type}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Firm Type" />}
                                        >
                                            <MenuItem value="Private Limited">Private Limited</MenuItem>
                                            <MenuItem value="Partnership">Partnership</MenuItem>
                                            <MenuItem value="Properietorship"> Properietorship</MenuItem>
                                        </Newselect>
                                    </FormControl>
                                </Box>

                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 mt-4 mt-md-0">
                                <TextField
                                    label="Address*"
                                    name="address"
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    label="City*"
                                    name="city"
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    label="State"
                                    name="state"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="Pincode*"
                                    name="pinCode"
                                />
                            </div>

                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="Distance From Station"
                                    name="station_distance"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="PAN No.*"
                                    name="company_pan"
                                />
                            </div>
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="GSTIN*"
                                    name="gstin"
                                />
                            </div>
                            <div className="col-md-3 mt-4  mt-md-2 ">

                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">FSSAI Status</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"

                                            name="fssai_status"
                                            value={formik.values.fssai_status}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="FSSAI Status" />}
                                        >

                                            <MenuItem value="true">Yes</MenuItem>
                                            <MenuItem value="false">No</MenuItem>
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    label="FSSAI*"
                                    name="fssai_lic_num"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">

                            <div className="col-md-3 mt-4  mt-md-2 ">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"

                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Status" />}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="Closed">Closed</MenuItem>
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="col-md-3 mt-4  mt-md-2 ">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Resturant Type</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="restaurant_type"
                                        value={formik.values.restaurant_type}
                                        onChange={(e) => formik.setFieldValue("restaurant_type", e.target.value)}
                                        label="Age"

                                        multiple
                                        size="small"
                                        input={<OutlinedInput label="Resturant Type" />}
                                    >
                                        <MenuItem value="veg"> Veg</MenuItem>
                                        <MenuItem value="Non-Veg">Non-Veg</MenuItem>
                                        <MenuItem value="Jain">Jain</MenuItem>



                                    </Newselect>
                                </FormControl>
                            </div>
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    type="date"
                                    name="fssaiValidUpto"
                                    label="FSSAI Valid Upto"
                                    value={formik.values.fssaiValidUpto}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>


                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    name="min_order_amount"
                                    label="Minimum Order Amount"
                                />


                            </div>
                            <div className="col-md-3 mt-4  mt-md-0 ">
                                <TextField
                                    name="min_order_time"
                                    label="Minimum Order Time"
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0 ">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            slotProps={{ TextField: { size: 'small' } }}
                                            value={moment(formik.values.opening_time, "HH:mm")}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("opening_time", value.format("HH:mm"))}
                                            name="opening_time" label="Opening Time" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            slotProps={{ TextField: { size: 'small' } }}
                                            value={moment(formik.values.closing_time, "HH:mm")}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("closing_time", value.format("HH:mm"))}
                                            name="closing_time" label="Closing Time" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>

                        </div>

                        <hr />
                        <Typography>Contact Details</Typography>
                        <div className="row mt-3">

                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="contact_person"
                                    label="Contact Person*"
                                    value={formik.values.contact_details.contact_person}
                                    onChange={(e) => formik.setFieldValue("contact_details.contact_person", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="email"
                                    label="Email*"
                                    value={formik.values.contact_details.email}
                                    onChange={(e) => formik.setFieldValue("contact_details.email", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="contact_number"
                                    label="Contact Number*"
                                    value={formik.values.contact_details.contact_number}
                                    onChange={(e) => formik.setFieldValue("contact_details.contact_number", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="alt_number"
                                    label="Alternate No."
                                    value={formik.values.contact_details.alt_number}
                                    onChange={(e) => formik.setFieldValue("contact_details.alt_number", e.target.value)}
                                />
                            </div>
                        </div>
                        <hr />
                        <Typography>Bank Account Details</Typography>
                        <div className="row mt-3">

                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="ac_holder_name"
                                    label="Account Name*"
                                    value={formik.values.bank_ac_details.ac_holder_name}
                                    onChange={(e) => formik.setFieldValue("bank_ac_details.ac_holder_name", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="account"
                                    label="Account No.*"
                                    value={formik.values.bank_ac_details.account}
                                    onChange={(e) => formik.setFieldValue("bank_ac_details.account", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 mt-4 mt-md-0">
                                <TextField
                                    name="ifsc"
                                    label="IFSC Code*"
                                    value={formik.values.bank_ac_details.ifsc}
                                    onChange={(e) => formik.setFieldValue("bank_ac_details.ifsc", e.target.value)}
                                />
                            </div>
                        </div>

                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={2}
                            mb={2}
                            mt={3}
                        >
                            <SubmitButton label="Update Restaurant" />
                            <SubmitButton onClick={() => setUpdateModal(false)} label="Cancel" />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UpdateResturantForm
