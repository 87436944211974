import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";


const ORDERS_QUERY_KEY = 'orders';
const ORDER_QUERY_KEY = 'order';

// Get Orders List
export function useOrdersList() {
    const { isLoading, data } = useQuery([ORDERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.orders.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { ordersData: data, ordersIsLoading: isLoading }
}

// Get Order Detail
export function useOrderDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [ORDER_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.orders.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { orderData: data, orderIsLoading: isLoading, isError, status }
}


export function useUpdateOrderStatus(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.statusUpdate}/${id}`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values),
	}, true))
    return { 
        handleUpdateOrder: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data) {
                    await queryClient.invalidateQueries([ORDER_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}



export function useCheckOrderStatus(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [ORDERS_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.orders.statusCheck}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { statusData: data, statusisLoading: isLoading, isError, status }
}

const PLACE_ORDER_KEY = 'placeorder'

export function usePlaceOrder() {
    const queryClient = useQueryClient();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.placeOrder}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handlePlaceOrder: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([PLACE_ORDER_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        isPlaceOrderLoading: mutation.isLoading,
    }
}



const COUPON_QUERY_KEY ='couponkey'

export function useApplyCoupon() {
    const queryClient = useQueryClient();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.couponValidate}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handleCoupon: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([COUPON_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        isCouponLoading: mutation.isLoading,
    }
}

export function useConfirm() {
    const {accessToken} = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((id) => fetchJson(`${API_HOST_URL}/${endpoints.orders.confirm}/${id}` , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    } , true))
    return {
        handleConfirm: async (id) => {
            try {
                const res = await mutation.mutateAsync(id);
                const data = await res.json();
                queryClient.invalidateQueries([ORDER_QUERY_KEY]);
                return data;
            } catch (error) {
                return {success:false , err:"something Went Wrong"}
            }
        },
        isConfirmLoading:mutation.isLoading
    }
}

export function useChangeRes(id) {
    const {accessToken} = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation({mutationFn:(values) => fetchJson(`${API_HOST_URL}/api/v1/order/admin/swap/restaurant/${id}` , {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body:JSON.stringify(values)
    } , true)})
    return {
        handleChangeRes: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                queryClient.invalidateQueries([ORDER_QUERY_KEY]);
                return data;
            } catch (error) {
                return{
                    success:false,error
                }
            }
        },
        isChangeLoading:mutation.isLoading
    }
}