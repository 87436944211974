import React, { useMemo, useState } from "react";
import { useCusineType } from "../hooks/item";
import DataTable from "react-data-table-component";
import FilterComponent from "../Common/FilterComponent";
import Loader from "../Loader";
import CusineAddModal from "../model/CusineAddModal";

const CusineCont = () => {
  const { cusineTypeData, cusineIsLoading } = useCusineType();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "cusine Name",
      selector: (row) => row.cusineName,
      cell: (row) => row.cusineName,
      sortable: true,
    },
    {
      name: "Cusine Value",
      selector: (row) => row.cusineValue,
      sortable: true,
    },

    {
      name: "Active",
      selector: (row) => (row.active ? "Yes" : "No"),
    },
  ];

  if (cusineIsLoading) {
    return <Loader />;
  }

  const filteredItems = cusineTypeData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  return (
    <>
      <div className="container" style={{ padding: "20px" }}>
        <div className="text-right">
          <button
            type="button"
            data-toggle="modal"
            data-target="#exampleModaladd"
            data-whatever="@getbootstrap"
            className="btn btn-dark"
          >
            Add Cusine <i className="fa fa-plus"></i>
          </button>
        </div>
        <div className="table-wrapper">
          <h3 className="text-center" style={{ padding: "5px" }}>
            Cusine Item List
          </h3>
          {
            <DataTable
              columns={columns}
              data={filteredItems}
              direction="auto"
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              progressPending={cusineIsLoading}
              // conditionalRowStyles={conditionalRowStyles}

              subHeaderAlign="right"
              subHeaderWrap
              subHeaderComponent={subHeaderComponent}
              subHeader
            />
          }
        </div>
      </div>

      <CusineAddModal
        id="exampleModaladd"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      />
    </>
  );
};

export default CusineCont;
