import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";


const VENDORS_QUERY_KEY = 'vendors';
const VENDOR_QUERY_KEY = 'vendor';

// Get Vendors List
export function useVendorsList() {
    const { isLoading, data } = useQuery([VENDORS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.vendors.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { vendorsData: data, vendorsIsLoading: isLoading }
}


export const stripStationsList = (data) => {
    return {
        value: data._id,
        label: `${data.stationName} (${data.stationCode})`
    }
}

export const stripVendorsList = (data) => {
    return {
        value: data._id,
        label: `${data.name}`
    }
}
export const stripCusineList = (data) => {
    return {
        value: data.cusineValue,
        label: `${data.cusineName}`
    }
}
export const stripItemList = (data) => {
    return {
        value: data.food_type,
        label: `${data.food_type}`
    }
}
// Get Vendor Detail
export function useVendorDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [VENDOR_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.vendors.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { vendorData: data, vendorIsLoading: isLoading, isError, status }
}


// Add Vendor Hook
export function useVendorAdd() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.vendors.add}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            ...values
        })
    }, true), {
        retry: 2,
    })

    return {
        handleAddVendor: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([VENDORS_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        addVendorIsLoading: mutation.isLoading,
    }
}




export function useUpdateVendor(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.vendors.updateByid}/${id}`, {
		method: 'PUT',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleUpdateVendor: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([VENDORS_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}


