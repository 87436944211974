import React from 'react'
import { Form, Formik } from "formik";
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, OutlinedInput, Stack, Typography, createFilterOptions } from '@mui/material';
import TextField from '../Common/MuiTextField';
import { useStationsList, stripStationsList } from "../hooks/stations"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useVendorsList, stripVendorsList } from "../hooks/vendors"
import SubmitButton from '../Common/Button';
import Newselect from '../Common/Newselect';
import * as Yup from 'yup'
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';


const firm_type_options = [
    { label: "Private Limited", value: "Private Limited" },
    { label: "Partnership Firm", value: "Partnership" },
    { label: "Proprietorship", value: "Properietorship" },
];
const restaurant_type_options = [
    { label: "Veg", value: "veg", id: "1" },
    { label: "Non-Veg", value: "Non-Veg", id: "2" },
    { label: "Jain", value: "Jain", id: "3" },
];

const delivery_type_options = [
    { label: "Delivery", value: "delivery" },
    { label: "Pickup", value: "pickup" },
];
const status_type_options = [
    { label: "Active", value: "Active" },
    { label: "Deactive", value: "Closed" },
];
const fssai_status_type_options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

const AddResturantForm = ({ isLoading, handleSubmit, modalIsOpen, setModalIsOpen }) => {
    const { stationsData, stationsIsLoading } = useStationsList();
    const { vendorsData, vendorsIsLoading } = useVendorsList();

    const OPTIONS_LIMIT = 10;

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
        limit: 10
    });

    const validate = Yup.object({
        name: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter restaurant name."),
        brand: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter brand name"),
        station_distance: Yup.string().trim("Remove Spaces").strict(true).min(1).required("Eneter station distance"),
        address: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter address"),
        firm_name: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter Firm name"),
        company_pan: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter Pan number"),
        gstin: Yup.string().trim("Remove Spaces").strict(true).min(4).required("Enter GST number"),
        email: Yup.string().trim("Remove Spaces").strict(true).email().required("Enter email address."),
        contact_number: Yup.number().required("Enter contact number."),
        fssai_lic_num: Yup.string().trim("Remove Spaces").strict(true).required("Enter fssai licence number."),
        closing_time: Yup.string().required("Enter closing time."),
        opening_time: Yup.string().required("Enter opening time."),
        contact_person: Yup.string().trim("Remove Spaces").strict(true).required("Enter contact person."),
        ac_holder_name: Yup.string().trim("Remove Spaces").strict(true).required("Enter account holder name"),
        account: Yup.string().trim("Remove Spaces").strict(true).required("Enter account number"),
        ifsc: Yup.string().trim("Remove Spaces").strict(true).required("Enter Ifsc code"),
        min_order_amount: Yup.string().trim("Remove Spaces").strict(true).required("Enter Minimum Order Amount."),
        min_order_time: Yup.string().trim("Remove Spaces").strict(true).required("Enter Minimum Order Time."),
        city: Yup.string().trim("Remove Spaces").strict(true).required("Enter city name"),
        state: Yup.string().trim("Remove Spaces").strict(true).required("Enter state name"),
        pinCode: Yup.number().required("Enter pincode"),
        fssaiValidUpto: Yup.date().required("Enter fssai validation date")


    })

    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                    brand: "",
                    userId: null,
                    station: "",
                    station_distance: "",
                    address: "",
                    firm_name: "",
                    firm_type: "", // Private Limited, Partnership Firm, Proprietorship
                    company_pan: "",
                    gstin: "",
                    contact_person: "",
                    email: "",
                    contact_number: "",
                    alt_number: "",
                    restaurant_type: [], // * Veg, Non-Veg, Jain
                    delivery_type: "delivery", // delivery, pickup
                    fssai_status: true,
                    fssai_lic_num: "",
                    status: "Active",
                    opening_time: "",
                    closing_time: "",
                    ifsc: "",
                    account: "",
                    ac_holder_name: "",
                    min_order_time: "",
                    min_order_amount: "",
                    city: "",
                    state: "",
                    pinCode: "",
                    fssaiValidUpto: "",
                }}

                validationSchema={validate}
                // onSubmit={handleSubmit}
                onSubmit={(values, { resetForm }) => {

                    handleSubmit(values, resetForm);
                }}

            >
                {(formik, errors, touched) => (
                    <Form  >
                        <div className='row'>


                            <div className="col-md-6">
                                <Autocomplete
                                    disablePortal
                                    size='small'
                                    id="combo-box-demo"
                                    options={stationsData?.map(stripStationsList)}
                                    getOptionLabel={(option) => option.label}
                                    loading={stationsIsLoading}
                                    filterOptions={filterOptions}
                                    value={formik.values.station?.label}
                                    onChange={(e, value) => value == null ? value = "" :
                                        formik.setFieldValue("station", value.value)}
                                    slotProps={{ textField: { size: 'small' } }}
                                    slots={{ loadingOverlay: LinearProgress, }}
                                    renderInput={(params) => <TextField {...params} name="station" label="Station" />}
                                    isOptionEqualToValue={(options, value) => options.value === value.value}
                                />
                            </div>
                            <div className='col-md-6 mb-2  mt-3 mt-md-0'>
                                <Autocomplete
                                    disablePortal
                                    size='small'
                                    options={vendorsData?.map(stripVendorsList)}
                                    value={formik.values.userId?.label}
                                    slots={{ loadingOverlay: LinearProgress, }}
                                    loading={vendorsIsLoading}
                                    onChange={(e, value) => value == null ? value = "" : formik.setFieldValue("userId", value.value)}
                                    slotProps={{ textField: { size: 'small' } }}
                                    renderInput={(params) => <TextField name="userId"      {...params} label="Select Vendor" />}
                                    isOptionEqualToValue={(options, value) => options.value === value.value}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="Resturant Name *"
                                    name="name"
                                />
                            </div>
                            <div className="col-md-3  mt-3  mt-md-0">
                                <TextField
                                    label="Brand Name *"
                                    name="brand"
                                />
                            </div>
                            <div className="col-md-3  mt-3  mt-md-0">
                                <TextField
                                    label="Firm Name *"
                                    name="firm_name"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-2">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Firm Type</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="firm_type"
                                            value={formik.values.firm_type}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Firm Type" />}
                                        >
                                            {firm_type_options.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6  mt-3 mt-md-0">
                                <TextField
                                    label="Address*"
                                    name="address"
                                />
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="City*"
                                    name="city"
                                />
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="State"
                                    name="state"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="Pincode*"
                                    name="pinCode"
                                />
                            </div>

                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="Distance From Station"
                                    name="station_distance"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="PAN No.*"
                                    name="company_pan"
                                />
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="GSTIN*"
                                    name="gstin"
                                />
                            </div>
                            <div className="col-md-3  mt-3 mt-md-2">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">FSSAI Status</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="fssai_status"
                                            value={formik.values.fssai_status}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="FSSAI Status" />}
                                        >
                                            {fssai_status_type_options.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    label="FSSAI*"
                                    name="fssai_lic_num"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3  mt-3 mt-md-2">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Delivery Type</InputLabel>
                                        <Newselect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="delivery_type"
                                            value={formik.values.delivery_type}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Delivery Type" />}
                                        >
                                            {delivery_type_options.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="col-md-3  mt-3 mt-md-2">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Newselect
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                            input={<OutlinedInput label="Status" />}
                                        >
                                            {status_type_options.map((element, id) => {
                                                return (
                                                    <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                                )
                                            })}
                                        </Newselect>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="col-md-3  mt-3 mt-md-2">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Resturant Type</InputLabel>
                                    <Newselect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="restaurant_type"
                                        value={formik.values.restaurant_type}
                                        onChange={(e) => formik.setFieldValue("restaurant_type", e.target.value)}
                                        label="Age"
                                        multiple
                                        size="small"
                                        input={<OutlinedInput label="Resturant Type" />}
                                    >
                                        {restaurant_type_options.map((element, id) => (
                                            <MenuItem key={id} value={element.value}> {element.label}</MenuItem>
                                        ))}
                                    </Newselect>
                                </FormControl>
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    type="date"
                                    name="fssaiValidUpto"
                                    label="FSSAI Valid Upto"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    name="min_order_amount"
                                    label="Minimum Order Amount"
                                />
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    name="min_order_time"
                                    label="Minimum Order Time"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0 ">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={moment(formik.values.opening_time, "HH:mm")}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("opening_time", value.format("HH:mm"))}
                                            name="opening_time" label="Opening Time" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className="col-md-3  mt-3 mt-md-0">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={moment(formik.values.closing_time, "HH:mm")}
                                            ampm={false}
                                            onChange={(value) => formik.setFieldValue("closing_time", value.format("HH:mm"))}
                                            name="closing_time" label="Closing Time" />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="row mt-3">
                        </div>
                        <hr />
                        <Typography>Contact Details</Typography>
                        <div className="row mt-3">
                            <div className="col-md-3  mt-3 mt-md-0">
                                <TextField
                                    name="contact_person"
                                    label="Contact Person*"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0">
                                <TextField
                                    name="email"
                                    label="Email*"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0">
                                <TextField
                                    name="contact_number"
                                    label="Contact Number*"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0">
                                <TextField
                                    name="alt_number"
                                    label="Alternate No."
                                />
                            </div>
                        </div>
                        <hr />
                        <Typography>Bank Account Details</Typography>
                        <div className="row mt-3">
                            <div className="col-md-3 mt-3 mt-md-0">
                                <TextField
                                    name="ac_holder_name"
                                    label="Account Name*"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0">
                                <TextField
                                    name="account"
                                    label="Account No.*"
                                />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0">
                                <TextField
                                    name="ifsc"
                                    label="IFSC Code*"
                                />
                            </div>
                        </div>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={2}
                            mb={2}
                            mt={3}
                        >
                            <SubmitButton loading={isLoading} label="Add Restaurant" />
                            <SubmitButton onClick={() => setModalIsOpen(false)} label="Cancel" />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddResturantForm
