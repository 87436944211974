import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ScheduleClosedform from "../form/ScheduleClosedForm"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '50%',
};


export function ScheduleClosed({ scheduleModal,setScheduleModal }) {

    return (
        <div>
            <Modal
                open={scheduleModal}
                onClose={() => setScheduleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Schedule Closed</h5>
                            <IconButton onClick={() => setScheduleModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                            <ScheduleClosedform  setScheduleModal={setScheduleModal} />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}

{/*import Modal from "react-modal";
import React from "react";
import { Button } from "@mui/material";
import TextField from "../Common/MuiTextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const ScheduleClosed = ({ isOpen, setOpen, data, resId }) => {
  const customStyles = {
    content: {
      top: "8%",
      height: "90%",
      left: "12%",
      width: "75%",
      background: "none",
      border: "none",
    },
  };

  const validate = Yup.object({
    closedFrom: Yup.date(),
    closedTo : Yup.date()
  })

  const submitResturantUpdate = (value) => {
    console.log(value);
  }

  return (
    <Modal
      contentLabel="Schefule Closed"
      style={customStyles}
      isOpen={isOpen}
      ariaHideApp={false}
    >
      <div className="content-wrapper h-auto">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Schedule Closed
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => setOpen(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Formik
              initialValues={{
                closedFrom: "",
                closedTo: "",
              }}
              validationSchema={validate}
              onSubmit={submitResturantUpdate}
            >
              <Form>
                <div className="d-flex justify-content-between m-2 modal-header">
                  <TextField
                    type="date"
                    label="Closed From"
                    name="closedFrom"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Closed From"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="d-flex justify-content-between m-2 modal-header">
                  <TextField
                    type="date"
                    label="Closed To"
                    name="closedTo"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Closed To"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button variant="contained" type="submit" className="m-2">
                    {" "}
                    Update{" "}
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleClosed;
*/}
