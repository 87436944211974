import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getTokens } from '../../utils/core';
import { API_HOST_URL } from '../../config';

import { toast } from 'react-toastify';


const UploadMenuModel = ({ menuModal, setMenuModal }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [value, setValue] = useState()
    const handleSubmit = async (value) => {
        console.log(value)
        const formData = new FormData()
        formData.append("items", value)
        const { accessToken } = getTokens()
        const res = await fetch(`${API_HOST_URL}/api/v1/item/upload`,
            {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },

            }
        )
        const data = res.json()
        if (data.success) {
            toast.success("Menu Uploaded")
        } else {
            toast.error(data.error)
        }
    };

    return (
        <div>
            <Modal
                open={menuModal}
                onClose={() => setMenuModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="d-flex justify-content-between">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Upload Menu
                        </Typography>
                        <IconButton onClick={() => setMenuModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ padding: "20px" }}>
                        <input type="file" name="file" accept=".xls, .xlsx" onChange={(e) => setValue(e.target.value)} />
                    </div>
                    <button style={{ margin: "10px 0px" }} type="button" class="btn btn-primary float-end" data-dismiss="modal" onClick={() => handleSubmit(value)} >
                        Upload
                    </button>
                </Box>
            </Modal>
        </div>
    )
}

export default UploadMenuModel




