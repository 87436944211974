import React from 'react'
import AddCusineForm from '../form/AddCusineForm'

const CusineAddModal = () => {
  return   <div className="modal fade" id="exampleModaladd" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog" role="document">
 <AddCusineForm className="modal-content" />
</div>
</div>
  
}

export default CusineAddModal