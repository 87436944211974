import React from "react";
import FilterComponent from "../Common/FilterComponent";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link } from "react-router-dom";
import { useLatestOrdersList } from "../hooks/latest-order";
import { Budget } from "../Common/CardForValue";

const Dashboard = ({ user }) => {
  const { ordersData, ordersIsLoading } = useLatestOrdersList();
  const [filterText, setFilterText] = React.useState("");
// console.log(ordersData)
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const arr = ordersData || [];
  const Delivered =
    arr.length > 0 &&
    arr.filter(function (item) {
      return item.status === "ORDER_DELIVERED";
    });
  const Canceled =
    arr.length > 0 &&
    arr.filter(function (item) {
      return item.status === "ORDER_CANCELED";
    });

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row._id,
      cell: (row) => <Link to={`/orders/${row._id}`}>{row.orderId}</Link>,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
          <span> {row.deliveryDetails.passengerName} </span>
          <span>{row.mobile}</span>
        </div>
      ),
      
    },

    {
      name: "Station Name",
      selector: (row) => <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
      <span> { row.deliveryDetails.station} </span>
      <span>{ row.deliveryDetails.stationCode }</span>
    </div>,
      sortable: true,
      minWidth: "150px",
    },

    {
      name: "Net Total",
      selector: (row) =><div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
      <span>   {row.totalAmount ? row.totalAmount.$numberDecimal : ""} Rs. </span>
      <span>
      {row.paymentType === "CASH_ON_DELIVERY" ? (
          <span className="badge badge-danger">COD</span>
        ) : (
          <span className="badge badge-success">ONLINE</span>
        )}
        </span>
    </div>,
      sortable: true,
    },
    {
      name:"Order Source",
      selector:(row) => row.orderSource ? row.orderSource : "Web",
      sortable: true,
      maxWidth:'70px'
    },
    {
      name: "Resturant",
      selector: (row) =>row.resturant ?  row.resturant.name : "",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Ordered at",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY hh:mm A"),
      sortable: true,
      minWidth: "150px",
    },  {
      name: "Order Status",
      selector: (row) => (
        <span className="float-right">
          {row.status === "ORDER_PLACED" ||
          row.status === "ODRDER_PREPARING" ? (
            <span className="badge badge-warning">{row.status}</span>
          ) : row.status === "ORDER_ACCEPTED" ? (
            <span className="badge badge-primary">{row.status}</span>
          ) : row.status === "ORDER_READY" ||
            row.status === "ORDER_DELIVERED" ? (
            <span className="badge badge-success">{row.status}</span>
          ) : (
            <span className="badge badge-danger">{row.status}</span>
          )}
        </span>
      ),
      sortable: true,
      minWidth:'130px'
    },

  ];

  // console.log(ordersData)
  // Filter data
  const filteredItems =
    ordersData && ordersData.length > 0 && !ordersIsLoading
      ? ordersData.filter(
          (item) =>
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) !== -1
        )
      : [];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Resturants</span>
                  <span className="info-box-number">10</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Sold</span>
                  <span className="info-box-number">1000</span>
                </div>
              </div>
            </div> */}

            <div className="clearfix hidden-md-up" />
            {/* <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-shopping-cart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Sales</span>
                  <span className="info-box-number">760</span>
                </div>
              </div>
            </div> */}

            {/* <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-warning elevation-1">
                  <i className="fas fa-users" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">New Members</span>
                  <span className="info-box-number">2,000</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row" style={{marginBottom:'20px'}}>
            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Today's Order"} diff={12} icon={'fa fa-shopping-cart'} trend="up" sx={{ height: '100%' , backgroundColor:'#cce3d5' }} value={ordersData && !ordersIsLoading ? ordersData.length : 0}/>   
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Delivered Today"} icon={'fas fa-clipboard-check'} diff={12} trend="up" sx={{ height: '100%' , backgroundColor:'#b5ebc3' }} value={Delivered.length ? Delivered.length : 0}/>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Cancelled Today"} icon={'fas fa-ban'} diff={12} trend="down" sx={{ height: '100%' , backgroundColor:'#f7c3c3' }} value={Canceled.length ? Canceled.length : 0}/>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <Budget title={"Overall Order"} diff={40} icon={'fas fa-check'} trend="up" sx={{ height: '100%' , backgroundColor:'#f2eece' }} value={2000}/> 
            </div>
          </div>

          {user.role === "admin" ||
          user.role === "superadmin" ||
          user.role === "supportExecutive" ||
          user.role === "floorManager" ||
          user.role === "manager" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Latest Orders</h3>
                  </div>

                  <div className="table-wrapper">
                    {
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        progressPending={ordersIsLoading}
                        // conditionalRowStyles={conditionalRowStyles}

                        subHeaderAlign="right"
                        subHeaderWrap
                        subHeaderComponent={subHeaderComponent}
                        subHeader
                      />
                    }
                  </div>

                  <div className="card-footer clearfix">
                    <Link
                      to="/order"
                      className="btn btn-sm btn-secondary float-right"
                    >
                      View All Orders
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3">
              <div className="info-box mb-3 bg-warning">
                <span className="info-box-icon">
                  <i className="fas fa-tag" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Inventory</span>
                  <span className="info-box-number">5,200</span>
                </div>
              </div>

              <div className="info-box mb-3 bg-success">
                <span className="info-box-icon">
                  <i className="far fa-heart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Mentions</span>
                  <span className="info-box-number">92,050</span>
                </div>
              </div>

              <div className="info-box mb-3 bg-danger">
                <span className="info-box-icon">
                  <i className="fas fa-cloud-download-alt" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Downloads</span>
                  <span className="info-box-number">114,381</span>
                </div>
              </div>

              <div className="info-box mb-3 bg-info">
                <span className="info-box-icon">
                  <i className="far fa-comment" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Direct Messages</span>
                  <span className="info-box-number">163,921</span>
                </div>
              </div>
            </div> */}
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
