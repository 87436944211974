import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";


const ORDERS_QUERY_KEY = 'orders';

// Get Orders List
export function useLatestOrdersList() {
    const { isLoading, data } = useQuery([ORDERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.orders.latest}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { ordersData: data, ordersIsLoading: isLoading }
}

// Get Order Detail

