import { Box, Card } from '@mui/material'
import React from 'react'

import Loader from '../Common/Loader2'
import { useViewItem, useViewitem } from '../hooks/item'

const ItemDetails = ({ id }) => {

    const { item,itemdataIsLoading } = useViewitem(id)
   
    if (itemdataIsLoading)
        return <Loader/>
    return (
        <Card>
            <Box sx={{ padding: "10px" }}>
            <div className="row">
                    <div className='col-md-6 '>
                        <span className="item-details">Item Name </span>: {item?.itemName}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <span className="item-details">Item Description</span> {item?.description}
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mt-3'>
                        <span className="item-details"> Item Type  </span> :  {item.itemType}
                    </div>
                    <div className='col-md-6 mt-3 '>
                        <span className="item-details">Item Availability </span>  : {item.availability ? "Yes" : "No"}
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mt-3 '>
                        <span className="item-details">Cost Price  </span>   : Rs. {!item.basePrice ? "" : item.basePrice.$numberDecimal}
                    </div>
                    <div className='col-md-6 mt-3'>
                        <span className="item-details">Yatri Restro Price  </span>     : Rs. {!item.yrPrice ? "" : item.yrPrice.$numberDecimal}
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mt-3'>
                        <span className="item-details"> Item Category  </span> :   {item.category}
                    </div>
                    <div className='col-md-6 mt-3 '>
                        <span className="item-details">Best Selling </span>  : {item.isBestSelling? "Yes" : "No"}
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mt-3'>
                        <span className="item-details">GST </span> : {item.gst ?  item.gst.$numberDecimal: ""}
                    </div>
                    <div className='col-md-6 mt-3 '>
                        <span className="item-details">FestiveItem </span>  : {item.isFestiveItem ?  "Yes": "No"}
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mt-3'>
                        <span className="item-details">Opening Time </span> :  {item.opening_time}
                    </div>
                    <div className='col-md-6 mt-3 '>
                        <span className="item-details"s>Closing Time</span>  :  {item.closing_time }
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mt-3'>
                        <span className="item-details">Festive Start date </span> : {item.festiveStartDate}
                    </div>
                    <div className='col-md-6 mt-3 '>
                        <span className="item-details">Festive End Date</span>  : {item.festiveEndDate}
                    </div>
                </div>
   
            </Box>
        </Card>
    )
}

export default ItemDetails
